<template>
  <div
    class="layout-with-nav black-bkg gastro"
    @click.stop="showKeyboard = false"
    :class="{ hydrating }"
  >
    <article class="container pickup-container" v-if="showPickupPoint">
      <div class="header">
        <span>{{ 'it_marble_hall' | translate }}</span>
        <button
          class="btn-back"
          @click.stop="showPickupPoint = false"
        >
          &larr; {{ 'all_back' | translate }}
        </button>
      </div>
    </article>

    <article class="container gastro-container" v-show="step == 0">
      <div class="gastro_home_text">
        <h3>Gerstner Pausen–Catering</h3>
        <p v-html="$t('gastro_introtext')"></p>
        <div class="offeredby-container">
          <span>{{ 'gastro.offeredby' | translate }}</span>
          <gerstner-logo width=100></gerstner-logo>
        </div>
      </div>
      <button class="btn-default" @click.stop="step = 1">
        {{ 'gastro_offerbtn' | translate }}
      </button>
    </article>

    <article class="container gastro-article-container" v-if="step == 1">
      <product
        class="product"
        v-for="product in shopProducts"
        :key="product.article_number"
        :product="product"
        @add="addOne($event)"
        @remove="removeOne($event)"
        :count="cart[product.article_number]"
      ></product>
      <div class="gastro-sticky-footer" :class="{ hidden: cartTotal == 0 }">
        <div class="btn-continue-nextstep btn-default" @click.stop="step = 2">
          <span>{{ 'gastro_order' | translate }}</span><span>{{ 'gastro_total' | translate }}&nbsp;&nbsp;{{ cartTotal | fmtPrice }}</span>
        </div>
      </div>
    </article>

    <article class="container gastro-summary-container" v-if="step == 2">
      <h3>Gerstner Pausen–Catering</h3>
      <product-view
        class="product"
        v-for="product in shopProductsSelected"
        :key="product.article_number"
        :product="product"
        :count="cart[product.article_number]"
      ></product-view>
      <div class="sum-container">
        <span class="">{{ 'gastro_total' | translate }}</span>
        <span class="price-tag">{{ cartTotal | fmtPrice }}</span>
      </div>
      <div class="gastro-footer">
        <button class="btn-back" @click.stop="step = 1">
          &larr; &nbsp;{{ 'gastro_back' | translate }}
        </button>
        <button class="btn-default" @click.stop="step = 3">
          {{ 'gastro_placeorder' | translate }}
        </button>
      </div>
    </article>

    <article class="container gastro-finalize-container" :class="{ showKeyboard }" v-if="step == 3" ref="mainContainer">
      <div class="text-wrapper">
        <div style="padding: 30px; height: 100px; overflow: hidden;"><gerstner-logo width=100></gerstner-logo></div>
        <div class="gastro_home_text">
          <span class=""
            >{{ 'gastro_ordersum' | translate }}
            {{ cartTotal | fmtPrice }}</span
          >
          <div>
            <p v-html="$t('gastro_confirmation')"></p>
          </div>
        </div>
        <div class="email-input-wrapper" @click.prevent.stop="toggleKeyboard()">
          <label v-if="name.length > 0">{{
            'gastro_name_label' | translate
          }}</label>
          <input
            :value="name"
            readonly
            @click.prevent.stop="toggleKeyboard()"
            :placeholder="$t('gastro_name_label')"
          />
        </div>
      </div>

      <div class="button-wrapper" v-if="!sending">
        <button class="btn-back" @click.stop="step = 1">
          {{ 'gastro_cancel' | translate }}
        </button>
        <button
          class="btn-default"
          :disabled="name.length == 0 || sending"
          @click.stop="sendOrder()"
        >
          {{ 'gastro_sendorder' | translate }}
        </button>
      </div>
      <div v-if="sending">
        <span v-html="$t('gastro_sending')"></span>
      </div>

      <SimpleKeyboard
        :active="showKeyboard && step === 3"
        @onChange="onChange"
        @onKeyPress="onKeyPress"
        layout="text"
        @click.native.stop="() => {}"
        :input="name"
      />
    </article>

    <article
      class="container gastro-myorder-container"
      v-if="step == 4"
      ref="mainContainer"
    >
      <div style="padding: 30px; height: 100px; overflow: hidden;"><gerstner-logo width=100></gerstner-logo></div>
      <div class="text-wrapper" style="margin: 30px 0;">
        <p v-html="$t('gastro_orderpickupinfo')" style="margin: 20px 0;"></p>
        <p v-html="$t('gastro_alergen')"></p>
        <p>
          <span class="">
            {{ 'gastro_reservationname' | translate }}: </span
          ><br />
          <span class="" style="text-transform: uppercase">
            {{ order.alias }}
          </span>
        </p>
        <p v-html="order.seatdesc"></p>
      </div>

      <div class="button-wrapper">
        <button class="btn-default" @click.stop="$router.push('/start')">
          &larr; {{ 'it_goto_home' | translate }}
        </button>
        <button
          class="btn-back"
          @click.stop="showPickupPoint = true"
        >
          {{ 'gastro_locationplan' | translate }}
        </button>
        
      </div>
    </article>

    <base-nav />
  </div>
</template>

<script>
import Vue from 'vue'
import { articles } from '@/data/articles.js'
import { mapState } from 'vuex'
import Product from '@/components/gastro/Product.vue'
import ProductView from '@/components/gastro/ProductView.vue'
import SimpleKeyboard from '@/components/SimpleKeyboard.vue'
import GerstnerLogo from '@/components/icons/GerstnerLogo.vue'
import { v4 as uuid } from 'uuid'

const MAX = 10

export default {
  components: { Product, ProductView, SimpleKeyboard, GerstnerLogo },
  data() {
    return {
      step: -1,
      cart: {},
      showKeyboard: false,
      name: '',
      order: {
        order_id: null,
      },
      hydrating: false,
      showPickupPoint: false,
      sending: false,
    }
  },
  computed: {
    ...mapState('app', ['locale', 'unit', 'config']),
    shopProducts() {
      return articles.map(i => {
        const { title, description } = i.text[this.locale]
        return {
          ...i,
          title,
          description,
        }
      })
    },
    shopProductsSelected() {
      return this.shopProducts.filter(p => {
        return this.cart[p.article_number] > 0
      })
    },
    cartEmpty() {
      return Object.values(this.cart).reduce((acc, curr) => acc + curr, 0) == 0
    },
    cartTotal() {
      return this.shopProducts.reduce((acc, cur) => {
        return acc + cur.price * this.cart[cur.article_number]
      }, 0)
    },
  },
  watch: {
    showKeyboard(isVisible) {
      if (isVisible) {
        this.$nextTick(() => {
          this.$refs.mainContainer.scrollTop = 500
        })
      } else {
        this.$nextTick(() => {
          this.$refs.mainContainer.scrollTop = 0
        })
      }
    },
  },
  methods: {
    removeOne(sku) {
      this.cart[sku] = this.cart[sku] === 0 ? 0 : this.cart[sku] - 1
    },
    addOne(sku) {
      this.cart[sku] = this.cart[sku] === MAX ? MAX : this.cart[sku] + 1
    },
    onChange(input) {
      this.name = input
    },
    onKeyPress(button) {
      if (button === '{enter}') {
        this.showKeyboard = false
      }
    },
    cartToArticles() {
      return articles
        .map(a => {
          if (this.cart[a.article_number] > 0) {
            return {
              id: a.id,
              quantity: this.cart[a.article_number],
            }
          } else {
            return null
          }
        })
        .filter(i => i !== null)
    },
    async sendOrder() {
      this.sending = true
      const order_id = uuid()
      const articles = this.cartToArticles()

      // await this.$axios.post(`${this.config.SERVER_API}/gastro/orders`, {
      //   seat: this.unit.id,
      //   seatdesc: this.unit.displayName,
      //   alias: this.name,
      //   order_id,
      //   articles,
      // })

      const { data: order } = await this.$axios.post(
        `${this.config.DATA_API}/unit/order`,
        {
          ...this.unit,
          order: {
            order_id,
            name: this.name,
            articles,
          },
        }
      )

      Vue.set(this, 'order', order)
      this.step = 4
      this.sending = false
    },
    toggleKeyboard() {
      if (this.showKeyboard === false) {
        this.name = ''
      }
      this.showKeyboard = !this.showKeyboard
    },
  },
  async created() {
    this.hydrating = true
    articles.forEach(a => {
      Vue.set(this.cart, a.article_number, 0)
    })

    const order = await this.$store.dispatch('app/getOrderStatus')

    if (order && order.order_id) {
      Vue.set(this, 'order', order)
      this.step = 4
    } else {
      this.step = 0
    }

    this.hydrating = false
  },
}
</script>

<style scoped>
.layout-with-nav.hydrating::before {
  content: '';
  display: block;
  z-index: 10000;
  width: 100%;
  height: 600px;
  background: black;
}
.layout-with-nav.hydrating::after {
  content: '...loading';
  display: block;
  position: fixed;
  top: 300px;
  left: 512px;
  transform: translate(-50%, -50%);
  color: white;
}

.gastro {
  font-family: 'GT Super Vienna Display'; 
  font-size: 30px;
  line-height: 35px;
}
.gastro h3 {
  margin: 0 0 60px 0;
  padding-top: 0px;
  text-transform: uppercase;
}

.gastro p {
  font-size: 30px;
  line-height: 35px;
}

.gastro .offeredby-container {
  position: relative;
  font-family: 'GT Super Display'; 
  width: 310px;
  height: 100px;
  margin: 0 auto;
  padding: 60px 0 0 0;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 22px;
  text-align: left;
}
.gastro .offeredby-container svg {
  position: absolute;
  top: 35px; 
  right: 0;
}

.gastro .btn-default {
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 18px;
  color: black;
  padding: 5px 15px;
  font-family: "GT Super Display";
  font-size: 19px;
  text-transform: uppercase;
  background-color: white;
}
.gastro .btn-default:focus {
  outline: none;
}

.gastro .btn-back {
  user-select: none;
  border: none;
  color: white;
  padding: 5px 15px;
  font-family: "GT Super Display";
  font-size: 19px;
  text-transform: uppercase;
  background-color: transparent;
}
.gastro .btn-back:focus {
  outline: none;
}
  

.gastro .gastro-sticky-footer {
  position: fixed;
  top: 540px;
  height: 60px;
  left: 0;
  width: 953px;
  padding: 10px 30px;
  background: black;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
}
.gastro .gastro-sticky-footer.hidden {
  top: 600;
  height: 0;
  padding: 0 30px;
}

.gastro .btn-continue-nextstep {
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    display: flex;
}
.gastro .btn-continue-nextstep > span:first-child {
  display: inline-block;
  width: 60%;
  margin: 0 auto;
  flex-grow: 1;
  text-align: center;
}
.gastro .btn-continue-nextstep > span:last-child {
  font-size: 30px;
}

.container .title {
  text-align: center;
  padding: 20px 0 10px 0;
  width: 70%;
  border-bottom: 2px solid rgba(238, 238, 238, 0.5);
  margin: 0 auto;
}
.gastro-container {
  height: 600px;
  padding: 0 100px 0px 40px;
}
.gastro-container * {
  user-select: none;
}

.gastro-container .gastro_home_text {
  font-size: 30px;
  padding: 20px 0;
  margin-bottom: 20px;
}

.gastro-article-container {
  text-align: left;
  overflow-y: auto;
  padding: 20px 100px 40px 40px;
  height: 600px;
}
.gastro-article-container * {
  user-select: none;
}
.gastro-article-container .gastro-footer {
  padding: 20px 0;
  text-align: right;
}

button:disabled {
  opacity: 0.5;
}

.gastro-summary-container {
  text-align: left;
  overflow-y: auto;
  height: 600px;
  padding: 0 100px 40px 40px;
}
.gastro-summary-container h3 {
  padding-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.gastro-summary-container .sum-container {
  text-transform: uppercase;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  font-size: 30px;
  line-height: 35px;
  text-align: right;
}
.gastro-summary-container .sum-container .price-tag {
  width: 140px;
  display: inline-block;
}

.gastro-summary-container .gastro-footer {
  padding: 20px 0;
  text-align: right;
}
.gastro-summary-container .gastro-footer button:first-child {
  float: left;
}

.gastro-finalize-container {
  height: 600px;
  padding: 0 100px 0px 0px;
  overflow-y: auto;
}
.gastro-finalize-container * {
  user-select: none;
}

.gastro-finalize-container .gastro_home_text {
  font-size: 30px;
  line-height: 35px;
  padding: 20px 0;
  margin-bottom: 20px;
}

.gastro-finalize-container .text-wrapper {
  width: 600px;
  margin: 0 auto;
}

.gastro-finalize-container .button-wrapper button {
  margin: 20px;
}

.email-input-wrapper {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 48px;
  margin-top: 48px;
  position: relative;
  font-family: 'GT Super Vienna Display'; 
}
.email-input-wrapper label {
  display: block;
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 18px;
}
.email-input-wrapper input {
  border: none;
  background: transparent;
  width: 100%;
  height: 54px;
  line-height: 54px;
  font-size: 34px;
  font-weight: 300;
  border-bottom: 1px solid #fff;
  font-family: 'GT Super Vienna Display Italic'; 
  color: white;
}
.email-input-wrapper input:focus {
  outline: none;
}
.email-input-wrapper input::placeholder,
.email-input-wrapper input::-webkit-input-placeholder {
  color: white;
}

article.showKeyboard {
  padding-bottom: 300px;
}

.gastro-myorder-container {
  height: 600px;
  padding: 0 120px 40px 40px;
}
.gastro-myorder-container .text-wrapper {
  padding: 20px 0;
}

.gastro-myorder-container .button-wrapper button {
  margin: 20px;
}

.pickup-container {
  background: black url('/assets/gastro/pickuppoint.jpg') center center no-repeat;
  background-size: cover;

  height: 600px;

  padding: 0 0px 0px 0px;
}
.pickup-container * {
  user-select: none;
}

.pickup-container .header {
  font-size: 33px;
  padding: 0 20px;
  line-height: 50px;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: black;
  border-bottom: 1px solid #ccc;
}
.pickup-container .header span {
  flex-grow: 1;
}
</style>
