<template>
  <div class="newsletter layout-with-nav" @click.stop="showKeyboard = false">
    <article class="container" :class="{ showKeyboard }" ref="mainContainer">
      <div class="page-title">
        <h1 class="titleFont">{{ 'newsletter_header' | translate }}</h1>
      </div>

      <div class="content-text" v-if="done">
        <div class="text" v-html="$t('newsletter_success')"></div>
        <button
          class="back btn-golden-transparent"
          @click.stop="$router.push('/start')"
        >{{ 'newsletter_btn_back' | translate }}</button>
      </div>

      <div class="content-text" v-if="!done">
        <div class="text">{{ 'newsletter_description' | translate }}</div>

        <div class="form-check">
          <label>
            <div class="custom-checkbox" :class="{ checked: cb.news }"></div>
            <input type="checkbox" v-show="false" v-model="cb.news" />
            {{ 'newsletter_news' | translate }}
          </label>
        </div>
        <div class="form-check">
          <label>
            <div class="custom-checkbox" :class="{ checked: cb.jugend }"></div>
            <input type="checkbox" v-show="false" v-model="cb.jugend" />
            JUNGES PUPLIKUM
          </label>
        </div>
        <div class="form-check">
          <label>
            <div class="custom-checkbox" :class="{ checked: cb.ballett }"></div>
            <input type="checkbox" v-show="false" v-model="cb.ballett" />
            NEWSLETTER THEATERKARTEN
          </label>
        </div>

        <div class="text text--privacy" v-html="$t('newsletter_privacy')"></div>

        <div class="email-input-wrapper" @click.prevent.stop="toggleKeyboard()">
          <input
            name="email"
            :value="email"
            readonly
            :disabled="showKeyboard"
            required
            @click.stop="toggleKeyboard()"
            :placeholder="$t('newsletter_label')"
          />
          <button
              class="submit"
              :disabled="this.sending || !emailValid || !newsLetterSelected"
              @click.stop="subscribe()"
            >{{ 'newsletter_btn_subscribe' | translate }}</button>
          </div>
      </div>

      <SimpleKeyboard
        :active="showKeyboard"
        @onChange="onChange"
        @onKeyPress="onKeyPress"
        @click.native.stop="() => {}"
        :input="email"
      />
    </article>
    <base-nav />
  </div>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";
import { mapState } from "vuex";

export default {
  components: { SimpleKeyboard },
  data() {
    return {
      mwhidtm1: "",
      mwhidtm: "",
      email: "",
      showKeyboard: false,
      sending: false,
      cb: {
        news: true,
        jugend: false,
        ballett: false,
        familyfriends: false,
      },
      done: false,
    };
  },
  computed: {
    emailValid() {
      // eslint-disable-next-line no-useless-escape
      const re = new RegExp(/[^@]+@[^\.]+\..+/g);
      return re.test(this.email);
    },
    newsLetterSelected() {
      return Object.values(this.cb).some(i => i == true)
    },
    ...mapState("app", ["config"]),
  },
  watch: {
    showKeyboard(isVisible) {
      if (isVisible) {
        setTimeout(() => {
          this.$refs.mainContainer.scrollTop = 500;
        }, 0);
      } else {
        setTimeout(() => {
          this.$refs.mainContainer.scrollTop = 0;
        }, 250);
      }
    },
  },
  methods: {
    async subscribe() {
      // eslint-disable-next-line no-useless-escape
      const re = new RegExp(/[^@]+@[^\.]+\..+/g);
      
      if (!re.test(this.email)) {
        return;
      }

      this.sending = true;
      this.showKeyboard = false;

      try {
        const result = this.$axios.post(
          `${this.config.DATA_API}/newsletter/subscribe`,
          {
            email: this.email,
            ...this.cb,
          }
        );

        if (result.error) {
          this.sending = false;
          return;
        }

        const defFlags = { category: "Newsletter", action: "Registrierung" };
        const URI = `${this.config.STATS_API_REST}/track/event`;
        if (this.cb.news) {
          this.$axios.post(URI, { ...defFlags, label: "News" });
        }
        if (this.cb.jugend) {
          this.$axios.post(URI, { ...defFlags, label: "Jugend" });
        }
        if (this.cb.familyfriends) {
          this.$axios.post(URI, { ...defFlags, label: "Family&Frieds" });
        }
        if (this.cb.ballett) {
          this.$axios.post(URI, { ...defFlags, label: "Wiener Staatsballett"});
        }
      } catch (e) {
        // console.error(e)
        //noop
      }

      this.done = true;
      this.email = "";
      this.sending = false;
    },
    getTime() {
      const now = new Date();
      var month = now.getUTCMonth();
      month = month * 1;
      month = month + 1;
      return (
        now.getUTCDate() +
        "." +
        month +
        "." +
        now.getUTCFullYear() +
        " " +
        now.getUTCHours() +
        ":" +
        now.getUTCMinutes() +
        ":" +
        now.getUTCSeconds()
      );
    },
    onChange(input) {
      this.email = input;
    },
    onKeyPress(button) {
      if (button === "{enter}") {
        this.showKeyboard = false;
      }
    },
    onInputChange(input) {
      this.email = input.target.value;
    },
    toggleKeyboard() {
      if (this.showKeyboard === false) {
        this.email = "";
      }
      this.showKeyboard = !this.showKeyboard;
    },
  },
  mounted() {
    this.mwhidtm = this.getTime();
  },
};
</script>

<style scoped>
.page-title {
  padding: 8px;
}
.container {
  padding: 0;
}
.content-text {
  width: 918px;
  text-align: left;
  margin: 0 auto;
  font-family: "GT Super Vienna Display", sans-serif;
}

article.showKeyboard {
  padding-bottom: 300px;
}

.text {
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 35px;
  margin-top: 26px;
}

.text--privacy {
  font-size: 24px;
  line-height: 28px;
  display:inline-block;
  height: 170px;
}

button:disabled {
  opacity: 0.5;
}

.email-input-wrapper {
  font-size: 40px;
  line-height: 34px;
  margin-top: 50px;
  margin-right: 22px;
  position: relative;
  width: 100%;
}
.email-input-wrapper label {
  display: block;
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 18px;
}
.email-input-wrapper input {
  border: none;
  background: #ccc;
  height: 40px;
  font-family: "GT Super Vienna Display", sans-serif;
  line-height: 40px;
  font-size: 18px;
  padding-left: 11px;
  text-transform: uppercase;
  width: 730px;
  display: inline-block;
  color: white;
}
.email-input-wrapper input:focus,
.email-input-wrapper input:not(:placeholder-shown) {
  outline: none;
  border: 1px solid white;
  background-color: black;
}
.email-input-wrapper input::placeholder,
.email-input-wrapper input::-webkit-input-placeholder {
  color: black;
}

.email-input-wrapper button {
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 18px;
  color: black;
  padding: 5px 15px;
  font-family: "GT Super Vienna Display", sans-serif;
  font-size: 19px;
  text-transform: uppercase;
  background-color: white;
  position: absolute;
  right: 0;
  top: 8px;
}
.email-input-wrapper button:disabled {
  background-color: black;
  color: white;
}

.form-check {
  position: relative;
  line-height: 28px;
  text-align: left;
  padding-left: 41px;
  font-size: 28px;
  margin-bottom: 8px;
}

.custom-checkbox {
  cursor: pointer;
}
.custom-checkbox::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 2px;
  border: 2px solid #ccc;
  border-radius: 50%;
}

.custom-checkbox.checked::before {
  background-color: #ccc;
}

.keyboard-wrapper {
  padding-top: 0;
  padding-bottom: 22px;
  width: 953px;
}
button.back {
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 18px;
  color: black;
  padding: 5px 35px;
  font-family: "GT Super Vienna Display", sans-serif;
  font-size: 19px;
  text-transform: uppercase;
  background-color: white;
}
</style>
