<template>
  <div class="warn-notification" v-if="showBlockNotification && blockNotificationType == 'custom'">
    <div class="icon-wrapper">
      <div class="icon" :style="`background-image: url('/img/stop-sign.png');`"></div>
      <div class="icon" :style="`background-image: url('/img/mask-sign.png');`"></div>
    </div>

    <div class="text-container" v-if="showDE">
      <h3 class="text-uppercase">Verehrtes Publikum!</h3>
      <p>
        Wir bitten Sie im Sinne der Sicherheit der Musiker, sich derzeit nicht über die Brüstung zum Orchestergraben zu beugen.
      </p>
      <p>
        Das Tragen des Mund-Nasen-Schutzes auch während der Vorstellung, steigert Ihre Sicherheit und jene der Musiker!
      </p>
      <p class="text-uppercase">
        Vielen Dank
      </p>
    </div>
    <div class="text-container" v-else>
      <h3 class="text-uppercase">Dear Visitor!</h3>
      <p>
        On behalf of the musicians’ security, we ask you not to lean forward over the parapet.
      </p>
      <p>
        Wearing mouth and nose protection during the performance increases your safety and that of the musicians!
      </p>
      <p class="text-uppercase">
        Thank you
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      showDE: true,
      toggleTimeout: null,
      toggleTime: 10000,
    }
  },
  computed: {
    ...mapState('app', ['showBlockNotification', 'blockNotificationText', 'blockNotificationIcon', 'blockNotificationType']),
  },
  watch: {
    showBlockNotification(isVisible) {
      if (isVisible) {
        this.toggleLang()
      }
    }
  },
  methods: {
    toggleLang() {
      this.showDE = !this.showDE
      if (this.showBlockNotification) {
        clearTimeout(this.toggleTimeout)
        this.toggleTimeout = setTimeout(() => {
          this.toggleLang()
        }, this.toggleTime)
      }
    }
  },
  mounted() {
    if (this.showBlockNotification) {
      this.toggleLang()
    }
  }
}
</script>

<style scoped>
.warn-notification {
  position: fixed;
  width: 1024px;
  height: 600px;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgb(166, 9, 0);
  padding: 0;
  font-size: 33px;
  box-shadow: 8px 8px 16px #000;
}


.icon-wrapper {
  position: absolute;
  width: 300px;
  height: 600px;
  left: 30px;
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: absolute;
  bottom: 40px;
  left: 50px;
  width: 250px;
  height: 250px;
}

.icon:first-child {
  top: 40px;
  bottom: auto;
}


.text-container {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 540px;
  height: auto;
  font-size: 44px;
  text-align: center;
}

.text-container p {
  margin: 30px 0;
}
</style>
