<template>
  <div class="product">
    <div
      class="product-image"
      :style="
        `background-image: url('/assets/gastro/${product.article_number}.jpg')`
      "
    ></div>
    <div class="product-desc">
      <span class="product-title">{{ product.title }}</span>
      <p v-html="product.description"></p>
    </div>
    <div class="product-price">
      {{ count > 0 ? `${count} x`: '' }} {{ product.price | fmtPrice }}
      <div class="ctrls">
        <div class="ctrl-remove" :class="{ active: count > 0 }" 
          @click.stop="$emit('remove', product.article_number)">-</div>
        <div class="ctrl-add" 
          @click.stop="$emit('add', product.article_number)">+</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    count: Number,
  },
}
</script>

<style scoped>
.product {
  position: relative;
  display: flex;
  align-items: center;

  height: 170px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.product-image {
  height: 125px;
  width: 125px;
  background-size: cover;
  background-position: -25px 0;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.product-desc {  
  padding: 0 10px;
  flex-grow: 1;
}
.product-desc * {
  font-size: 30px;
  line-height: 35px;
}
.product-desc .product-title {
  font-size: 30px;
  text-transform: uppercase;
}
.product-desc p {
  padding: 0;
  margin: 0;
}

.ctrls {
  position: absolute;
  bottom: 0px;
  width: 120px;
  right: 0;
}
.ctrls .ctrl-remove,
.ctrls .ctrl-add {
  width: 44px;
  height: 44px;
  font-family: 'GT Super Display';
  font-weight: 300;
  display: inline-block;
  background: #ddd;
  border-radius: 50%;
  color: #333;
  font-size: 80px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
}
.ctrls .ctrl-remove {
  margin-right: 20px;
  display: none;
  background-color: black;
  color: #ccc;
  border: 1px solid #ccc;
}
.ctrls .crtl-remove.active {
  display: inline-block;
}
.ctrls .ctrl-add {
  margin-right: 0;
}

.product-price {
  position: relative;
  width: auto;
  font-size: 30px;
  height: 150px;
  text-align: right;
}
</style>
