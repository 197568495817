<template>
  <div class="sub container-full-height" :class="librettoDisplayMode">
    <lang-selection
      v-show="selectedLang === null || showLangSelection"
    ></lang-selection>
    <current-queue v-if="librettoDisplayMode == 'standard'"
      v-show="selectedLang !== null && !showLangSelection"
    ></current-queue>
    <subtitles-2020 v-if="librettoDisplayMode == 'cast-2020'"
      v-show="selectedLang !== null && !showLangSelection"
    ></subtitles-2020>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LangSelection from '@/components/LangSelection.vue'
import CurrentQueue from '@/components/CurrentQueue.vue'
import Subtitles2020 from '@/components/subtiteling/Subtitles2020.vue'

export default {
  components: { LangSelection, CurrentQueue, Subtitles2020 },
  computed: {
    ...mapState('subs', [
      'showLangSelection',
      'selectedLang',
      'passedFramesSinceLastLangChange',
      'librettoDisplayMode'
    ]),
    ...mapState('app', ['activated', 'config']),
  },
  watch: {
    passedFramesSinceLastLangChange(currVal) {
      if (currVal >= 5 && this.showLangSelection && this.activated) {
        this.$store.commit('app/TURN_ON_OFF', false)
        this.$store.commit('subs/SHOW_LANG_SELECTION', false)
      }
    },
    activated(gotActivated) {
      if (gotActivated) {
        this.$store.commit('subs/RESET_FRAMES_PASSED')
      }
    },
  },
  async created() {
    this.$store.commit('subs/SET_LIBRETTO', [])

    const { data: activeLanguages } = await this.$axios.get(
      `${this.config.DATA_API}/activelanguages`
    )
    this.$store.commit('subs/SET_ACTIVE_LANGUAGES', activeLanguages)

    this.$store.commit('app/TURN_ON_OFF', true)
    this.$store.commit('subs/SHOW_LANG_SELECTION', true)
  },
}
</script>

<style scoped>
.sub {
  background-color: black;
  padding: 0;
  position: relative;
}
.sub > div {
  opacity: 0.8;
}

.sub.cast-2020 {
  padding: 0;
}
</style>
