<template>
  <div class="warn-notification" v-if="showAlert && timeRemaining > 0">
    <div class="icon"></div>
    <div class="alert-info">
      <p class="text-uppercase">
        {{ 'warning.headline' | translate }}<br />
      </p>
      <p>
        {{ 'warning.salut' | translate }}<br>
        {{ infoLiteral }} <span class="timer-container">{{ minutesLeft }}</span>.<br>
        {{ 'warning.desc' | translate }}
      </p>
      <p v-html="$t('warning.thanks')"></p>
    </div>
    <div class="close-btn" @click.stop="showAlert=false">
      <svg width="30" height="30" class="svg-close"><path fill="none" stroke-miterlimit="10" d="M30.354.354l-30 30m0-30l30 30" stroke="white"></path></svg>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['timeRemaining', 'timerOffAction']),
    minutesLeft() {
      const timeRemaining = parseInt(this.timeRemaining, 10)

      if (timeRemaining < 60) {
        return this.$t('x_seconds', { count: timeRemaining }, timeRemaining)
      }

      const minutesRemaining = Math.ceil(timeRemaining / 60)
      return this.$t('x_minutes', { count: minutesRemaining }, minutesRemaining)
    },
    infoLiteral() {
      return this.timerOffAction === 'switch'
        ? this.$t('warning.switch')
        : this.$t('warning.turnoff')
    },
    showAlert: {
      get() {
        return this.$store.state.app.showAlert
      },
      set(value) {
        this.$store.commit('app/SET_SHOW_ALERT', value)
      },
    },
  },
}
</script>

<style scoped>
.warn-notification {
  position: fixed;
  width: 1024px;
  height: 600px;
  top: 0;
  left: 0;
  z-index: 500;
  background: rgba(166, 9, 0, 0.8);
  padding: 0;
  font-size: 33px;
  box-shadow: 8px 8px 16px #000;
}

.icon {
  background-size: cover;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
  width: 198px;
  height: 393px;
  background-image: url('/img/no-camera-sign.png');
}


.alert-info {
  text-align: center;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 340px;
}
.alert-info p {
  margin: 30px 0;
}

.close-btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 45px;
  height: 45px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50%;
}
</style>
