<template>
  <fragment>
    <div class="cast-and-crew">
      <article :data-navgroup="navgroup" class="content-slide full-height">
        <h3 class="text-uppercase">{{ 'det_leadingteam' | translate }}</h3>
        <div class="content-text">
          <div class="cast-row" v-for="(i, idx) in cast.L" :key="`${i.title}_${idx}`">
            <label v-html="i.title"></label>&nbsp;
            <span v-html="`${i.firstname} ${i.lastname}`"></span>
            <span @click.stop="openBiography(i)" class="arrow" v-if="i.biography || i.photo">&rarr;</span>
          </div>
        </div>
      </article>

      <article :data-navgroup="navgroup" class="content-slide">
        <h3 class="text-uppercase">{{ 'det_cast' | translate }}</h3>
        <div class="content-text">
          <div class="cast-row" v-for="(i, idx) in cast.K" :key="`${i.title}_${idx}`">
            <label v-html="i.title"></label>&nbsp;
            <span v-html="`${i.firstname} ${i.lastname}`"></span>
            <span @click.stop="openBiography(i)" class="arrow" v-if="i.biography || i.photo">&rarr;</span>
          </div>
        </div>
      </article>

      <cast-detail v-if="showBio" :cast="selectedCast" @close-cast-detail="showBio = false" />    
    </div>

  </fragment>
</template>

<script>
import CastDetail from './CastDetail.vue';


export default {
    props: {
        cast: Object,
        navgroup: Number,
    },
    data() {
        return {
            slider: null,
            castSlideIdx: 0,
            showBio: false,
            selectedCast: {}
        };
    },
    methods: {
        openBiography(cast) {
          this.selectedCast = cast
          this.showBio = true
        }
    },
    components: { CastDetail }
}
</script>

<style scoped>
article {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  height: max-content;
}
.content-text {
  font-size: 33px;
  line-height: 35px;
  text-align: left;
  margin-bottom: 200px;
  padding: 0 130px;
  width: 922px;
  float: left;
}
h3 {
  margin: 10px 0 60px 0;
}
.cast-row {
  width: 100%;
  text-align: center;
  line-height: 2;
}

.cast-row label {
  font-family: 'GT Super Vienna Display Italic';
}

.cast-row .arrow {
  margin-left: 20px;
}

.cast-bio {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  background: #ccc;
}
</style>
