export const articles = [
  {
    id: 9,
    article_number: 'p1',
    category: 3,
    price: 1350,
    active: true,
    text: {
      de: {
        title: 'Empfehlung 1',
        description:
          '1 Glas Sekt, 0,1 l<br>1 Sandwich mit Lachs<br>1 Gerstner Petit Four',
      },
      en: {
        title: 'Recommendation 1',
        description:
          '1 Glass of Sparkling Wine, 0.1 l<br>1 Smoked Salmon Sandwich<br>1 Gerstner Petit Four',
      },
    },
    image: null,
  },
  {
    id: 10,
    article_number: 'p2',
    category: 3,
    price: 1140,
    active: true,
    text: {
      de: {
        title: 'Empfehlung 2',
        description:
          '1 Glas Weißwein, 0,125 l<br>1 Mineralwasser, 0,33 l<br>1 Sandwich',
      },
      en: {
        title: 'Recommendation 2',
        description:
          '1 Glas White Wine, 0.125 l<br>1 Mineral Water, 0.33 l<br>1 Sandwich',
      },
    },
    image: null,
  },
  {
    id: 11,
    article_number: 'p3',
    category: 3,
    price: 1010,
    active: true,
    text: {
      de: {
        title: 'Empfehlung 3',
        description:
          '1 Orangensaft, 0,2 l<br>1 Sandwich mit Hummus<br>1 Gerstner Konfekt',
      },
      en: {
        title: 'Recommendation 3',
        description:
          '1 Orange Juice, 0.2 l<br>1 Sandwich with Hummus<br>1 Gerstner Confectionary',
      },
    },
    image: null,
  },
  {
    id: 12,
    article_number: 'p4',
    category: 3,
    price: 920,
    active: true,
    text: {
      de: {
        title: 'Empfehlung 4',
        description:
          '1 Café Melange<br>1 Mineralwasser, 0,33 l<br>1 Gerstner Petit Four',
      },
      en: {
        title: 'Recommendation 4',
        description:
          '1 Coffee Vienna Melange<br>1 Mineral Water, 0.33 l<br>1 Gerstner Petit Four',
      },
    },
    image: null,
  },
]
