<template>
  <div
    class="production-detail container-full-height layout-with-nav"
  >
    <div ref="scrollContainer" class="content-wrapper">
      <title-slide
        data-navgroup="1"
        :item="production"
        :title="$t('production_today')"
      ></title-slide>

      <cast-slides
        :navgroup="2"
        :cast="group"
        v-for="(group, idx) in castGroups"
        :key="`cg_${idx}`"
      ></cast-slides>
      
      <content-slides :navgroup="3" v-if="contentSlides.length > 0" :contentSlides="contentSlides"></content-slides>
      
      <!-- <div :data-navgroup="hasUpcomingEvents ? 4 : 3" class="full-height"
        :style="
          `background: black url(${config.STATIC_IMAGES}${production.headerImage}) center center no-repeat; background-size: cover;`
        "
      ></div> -->
      
      <upcoming-slide
        data-navgroup="4"
        :items="production.upcoming"
        v-if="hasUpcomingEvents"
      >
      </upcoming-slide>
      <sponsor-slide :data-navgroup="hasUpcomingEvents ? 5 : 4">
        <div class="prev" @click.stop="$router.push('/start')">&larr;&nbsp;{{ 'nav.main' | translate }}</div>
        <div class="next" @click.stop="$router.push('/about')">{{ 'nav.about' | translate }}&nbsp;&rarr;</div>
      </sponsor-slide>
    </div>

    <base-nav>
      <div class="dots__dotwrapper">
        <div class="dot" @click.stop="scrollTo(idx)" :class="{ active : idx === selectedIdx } " v-for="(dot, idx) in 4" :key="idx"></div>
      </div>
    </base-nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TitleSlide from '@/components/TitleSlide.vue'
import CastSlides from '@/components/CastSlides.vue'
import ContentSlides from '@/components/ContentSlides.vue'
import UpcomingSlide from '@/components/UpcomingSlide.vue'
import SponsorSlide from '@/components/SponsorSlide.vue'
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider'
import throttle from 'lodash/throttle'

export default {
  name: 'productiondetail',
  components: {
    TitleSlide,
    ContentSlides,
    SponsorSlide,
    CastSlides,
    UpcomingSlide,
  },
  data() {
    return {
      selectedIdx: 0,
    }
  },
  computed: {
    ...mapState('app', ['locale', 'config', 'production']),
    contentSlides() {
      if (!this.production.shortDescription || this.production.shortDescription.length == 0) return []
      return this.production.shortDescription
    },
    hasUpcomingEvents() {
      if (this.production && Array.isArray(this.production.upcoming)) {
        return this.production.upcoming.length > 0
      }
      return false
    },
    castGroups() {
      const groups = {}
      if (!this.production.cast) return []
      this.production.cast.forEach(c => {
        if (!groups[c.groupBy]) {
          groups[c.groupBy] = {
            K: [],
            L: [],
          }
        }
        groups[c.groupBy][c.roleType].push(c)
      })
      return Object.values(groups)
    },
  },
  methods: {
    _onScroll() {
      const scrollTop = this.$refs.scrollContainer.scrollTop
      let i = 0
      let selectedIdx = 0
      for (const child of this.$refs.scrollContainer.children) {
        if (child.offsetTop - 100 <= scrollTop) {
          selectedIdx = i
        }
        i ++;
      }
      this.selectedIdx = selectedIdx;
    },
    scrollTo(idx = 0) {
      const scrollToNode = this.$refs.scrollContainer.children[idx];
      this.$scrollTo(scrollToNode, 500, {
        container: this.$refs.scrollContainer,
        force: true,
        cancelable: false,
        x: false, y: true,
      })
    },
  },
  watch: {
    locale() {},
    production: {
      deep: true,
      handler() {
        this.loaded = true
      },
    },
  },
  mounted() {
    this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    this.handleScroll = throttle(this._onScroll.bind(this), 100, {leading: true, trailing: true})
  },
}
</script>

<style scoped>
 div.content-wrapper {
  overflow-y: scroll;
}
</style>