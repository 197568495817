<template>
  <div class="warn-notification" :class="{inverted : showDE}" v-if="active">
    <div class="icon-wrapper">
      
      <div class="icon icon-2" v-if="showDE" :style="`background-image: url('/img/triangle-sign-red.png');`"></div>
      <div class="icon icon-2" v-else :style="`background-image: url('/img/triangle-sign-white.png');`"></div>

    </div>

    <div class="text-container" v-if="showDE">
      <h3 class="text-uppercase">Sehr geehrte Damen und Herren,</h3>
      <p>
        Sie befinden sich direkt über dem Orchestergraben.<br>
        Herabfallende Gegenstände stellen eine akute <br>Gefahr
        für die Musiker*innen dar.
      </p>
      <p>
        Jegliches Hantieren mit Mobiltelefonen oder<br>
        Fotoapparaten an dieser Brüstung ist daher strengstens verboten.
      </p>
      <p class="text-uppercase">
        Die Missachtung dieser Sicherheitsbestimmung<br>führt zu einem sofortigen Saalverweis.
      </p>
      <p>
        Wir danken für Ihr Verständnis.
      </p>
    </div>
    <div class="text-container-bottom" v-if="showDE">
      
    </div>
    <div class="text-container" v-if="!showDE">
      <h3 class="text-uppercase">Ladies and Gentlemen,</h3>
      <p>
        Your seat is directly above the orchestra pit,<br> 
        where falling objects may pose a serious risk<br> 
        to the musicians below.
      </p>
      <p>
        Any use of cell phones or photographic devices by <br>
        this parapet wall is therefore strictly forbidden.
      </p>

      <p class="text-uppercase">
        Audience members failing to comply with these instructions will be asked to leave the theatre.
      </p>

      <p>
        Thank you for understanding.
      </p>
    </div>

    
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      showDE: true,
      toggleTimeout: null,
      toggleTime: 10000,
    }
  },
  computed: {
    ...mapState('app', ['showITBlockMessage']),
    active() {
      return this.showITBlockMessage && this.$route.name !== 'subtiteling'
    }
  },
  watch: {
    showITBlockMessage(isVisible) {
      if (isVisible) {
        this.toggleLang()
      }
    }
  },
  methods: {
    toggleLang() {
      this.showDE = !this.showDE
      if (this.showITBlockMessage) {
        clearTimeout(this.toggleTimeout)
        this.toggleTimeout = setTimeout(() => {
          this.toggleLang()
        }, this.toggleTime)
      }
    }
  },
  mounted() {
    if (this.showITBlockMessage) {
      this.toggleLang()
    }
  }
}
</script>

<style scoped>
.warn-notification {
  position: fixed;
  width: 1024px;
  height: 600px;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgb(166, 9, 0);
  padding: 0;
  font-size: 33px;
  box-shadow: 8px 8px 16px #000;
  /* transition: all 0.75s ease-in-out; */
}

.warn-notification.inverted {
  background: #fff;
  color: rgb(166, 9, 0);
}

.warn-notification .text-container * {
  font-family: 'GT Super Vienna Display';
  font-weight: bold!important;
}


.icon-wrapper {
  position: absolute;
  width: 180px;
  height: 180px;
  right: 90px;
  top: 90px;
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  /* transform: translateY(-50%); */
  width: 180px;
  height: 180px;
}


.icon-2 {
  top: 140px;
  bottom: auto;
}


.text-container {
  position: absolute;
  left: 50px;
  top: 30px;
  /* transform: translateY(-50%); */
  width: 960px;
  height: auto;
  font-size: 44px;
  text-align: left;
}

.inverted .text-container p {
  margin: 20px 0;
}

.text-container p {
  margin: 25px 0;
}
</style>
