<template>
  <div id="frame-view">
    <div class="selected-lang" v-if="activeFrame === null" :class="{ active: showSelectedLanguage }">
      {{ selectedLang | translate }}
    </div>
    <div class="frame-wrapper">
      <div v-show="activeFrame !== null" class="frame"
        v-html="activeFrameFormatted"
      ></div>
    </div>
    <div class="action-buttons">
      <div class="lang-switch" @click.stop="changeLanguage()">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 420 420" width="42" height="42" stroke="#fff" fill="none">
          <path stroke-width="22" d="M209,15a195,195 0 1,0 2,0z"/>
          <path stroke-width="10" d="m210,15v390m195-195H15M59,90a260,260 0 0,0 302,0 m0,240 a260,260 0 0,0-302,0M195,20a250,250 0 0,0 0,382 m30,0 a250,250 0 0,0 0-382"/>
        </svg>
      </div>
      <div @click.stop="$store.commit('app/TURN_ON_OFF', false)">
        <base-icon viewBox="0 0 42 42" :width="42" :height="42">
          <power-icon />
        </base-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PowerIcon from '@/components/icons/PowerOff.vue'

export default {
  components: { PowerIcon },
  data() {
    return {
      showSelectedLanguage: false,
      showSelectedLanguageTimeout: null
    }
  },
  computed: {
    ...mapState('app', ['activated']),
    ...mapState('subs', [
      'activeFrame',
      'selectedLang',
      'passedFramesSinceLastLangChange',
      'lastActiveCue',
    ]),
    activeFrameFormatted() {
      if (this.activeFrame === null) {
        return ''
      }
      return this.activeFrame
        .split('\n')
        .map(i => i.indexOf('l42-cast') === -1 ? `<div>${i}</div>` : i)
        .join('')
    },
  },
  watch: {
    passedFramesSinceLastLangChange(currVal) {
      if (currVal >= 5 && this.selectedLang === null && this.activated) {
        this.$store.commit('app/TURN_ON_OFF', false)
      }
    },
    selectedLang() {
      this.selectedLanguageAnimation()
    },
  },
  methods: {
    selectedLanguageAnimation(){
      if (this.showSelectedLanguageTimeout !== null) {
        clearTimeout(this.showSelectedLanguageTimeout)
      }
      this.showSelectedLanguage = true

      this.showSelectedLanguageTimeout = setTimeout(() => {
        this.showSelectedLanguage = false
      }, 5000)
    },
    changeLanguage() {
      this.$store.commit('subs/SHOW_LANG_SELECTION', true)
    },
  },
  mounted(){
    this.selectedLanguageAnimation()
  }
}
</script>

<style scoped>
#frame-view {
  height: 600px;
  text-align: left;
  width: 1024px;
  position: relative;
  overflow: hidden;
}

.frame-wrapper {
  position: relative;
  padding: 48px;
  color: #b2b2b2;
}

.selected-lang {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-size: 48px;
  font-weight: 200;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease;
}
.selected-lang.active {
  opacity: 1;
}

.frame {
  font-family: open_sanslight;
  text-align: left;
  font-size: 48px;
  line-height: 56px;
  white-space: nowrap;
}

.action-buttons {
  position: absolute;
  right: 48px;
  bottom: 48px;
  opacity: 0.6;
}
.lang-switch {
  width: 42px;
  height: 42px;
  margin-bottom: 27px;
  line-height: 42px;
  font-size: 20px;
}
</style>

<style>
l42-cast {
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 6px;
  font-style: italic;
  display: block;
  color: #9c861d;
  font-weight: bold;
  height: 35px;
  margin-top: 26px;
}
l42-cast:first-child{
  margin-top: 0;
}
</style>
