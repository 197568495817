<template>
  <div class="warn-notification" v-if="showBlockNotification && blockNotificationType == 'default'">
    <div class="alert-info alert-info--top">{{ blockNotificationText | translateIn('de') }}</div>
    <div class="icon" :style="`background-image: url('/img/${blockNotificationIcon}-sign.png');`"></div>
    <div class="alert-info  alert-info--bottom">{{ blockNotificationText | translateIn('en') }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['showBlockNotification', 'blockNotificationText', 'blockNotificationIcon', 'blockNotificationType']),
  },
}
</script>

<style scoped>
.warn-notification {
  position: fixed;
  width: 1024px;
  height: 600px;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgb(166, 9, 0);
  padding: 0;
  font-size: 33px;
  box-shadow: 8px 8px 16px #000;
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 350px;
}


.alert-info {
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  width: 80%;
  text-transform: uppercase;
  top: 40px;
  left: 50%;
  font-size: 44px;
}

.alert-info--bottom {
  top: auto;
  bottom: 40px;
}

.close-btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 45px;
  height: 45px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50%;
}
</style>
