<template>
  <div class="program-text">
    <div class="program-headline" v-html="$options.filters.toHTML(slide.headline)"></div>
    <div class="program-description" v-html="$options.filters.toHTML(slide.text)"></div>
  </div>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.program-text {
  width: 1024px;
  height: 600px;
  background: rgba(225, 225, 225, 1);
  color: black;
  text-align: center;
  padding: 60px;
}

.program-headline {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 60px;
  line-height: 55px;
  text-overflow: ellipsis;
}

.program-description {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 30px;
  line-height: 34px;
  align-self: end;
  margin: 60px 0;
}
</style>