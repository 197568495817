export const de = [
  {
    "id": 1,
    "title": "Werde ich nach Beginn der Vorstellung noch eingelassen?",
    "sorting": 1,
    "description": "Die Vorstellung beginnt pünktlich. Bitte beachten Sie, dass nach Vorstellungsbeginn der Saal (inkl. Logen) erst wieder in der Pause betreten werden darf. Dies gilt sowohl für zu spät kommende Gäste, als auch beim Verlassen des Saals während der Vorstellung. Dies gilt auch nach der Pause: Bitte kehren Sie rechtzeitig zu Ihrem Platz zurück. Zuspätkommende können die Vorstellung live auf einem Großbildschirm auf der Galerie oder beim Buffet im Parkett verfolgen.",
    "language": "DE",
    "published_at": "2020-10-09T11:34:22.136Z",
    "created_at": "2020-10-09T10:45:40.738Z",
    "updated_at": "2023-11-08T13:41:00.910Z"
  },
  {
    "id": 15,
    "title": "Wo finde ich die Pausenbuffets? ",
    "sorting": 1,
    "description": "In folgenden Räumlichkeiten stehen Pausenbuffets für Sie zur Verfügung: \n- Gustav Mahler-Saal \n- Marmorsaal \n- Galerie Pausenbuffet\n- Schwindfoyer & Schwindloggia \n- Buffets im Parterrelogenrang\n- Foyer im Parkett \nUm Wartezeiten zu verkürzen, können Sie Speisen und Getränke persönlich oder bis zwei Stunden vor Vorstellungsbeginn über www.staatsoper.gerstner.at vorbestellen. \n",
    "language": "DE",
    "published_at": "2021-06-17T10:12:44.200Z",
    "created_at": "2021-06-17T10:12:28.212Z",
    "updated_at": "2023-05-15T09:53:43.104Z"
  },
  {
    "id": 2,
    "title": "Darf ich Fotos oder Videos machen?",
    "sorting": 2,
    "description": "Aus urheberrechtlichen Gründen sind Bild-, Ton- und Videoaufnahmen während der Vorstellung nicht gestattet. In den Pausenräumen sowie vor und nach der Vorstellung dürfen Sie selbstverständlich Fotos zu privaten Zwecken machen. Aufnahmen zu journalistischen oder kommerziellen Zwecken bedürfen einer Genehmigung durch das Pressebüro.",
    "language": "DE",
    "published_at": "2020-10-13T14:36:51.950Z",
    "created_at": "2020-10-13T14:35:56.979Z",
    "updated_at": "2020-10-13T14:36:51.987Z"
  },
  {
    "id": 3,
    "title": "Wo kann ich Programmhefte kaufen?",
    "sorting": 3,
    "description": "Unsere Programmhefte mit umfassenden Informationen zum Komponist und zum Werk erhalten Sie beim Publikumsdienst (Preis € 5,80) oder digital unter »www.issuu.com/wienerstaatsoper« (Preis € 2,50). ",
    "language": "DE",
    "published_at": "2020-10-13T14:36:41.470Z",
    "created_at": "2020-10-13T14:36:38.633Z",
    "updated_at": "2023-10-16T14:36:15.854Z"
  },
  {
    "id": 5,
    "title": "Wo kann ich ein Opernglas ausleihen?",
    "sorting": 5,
    "description": "An den Garderoben können Sie beim Publikumsdienst um 2 € ein Opernglas ausleihen (solange der Vorrat reicht).",
    "language": "DE",
    "published_at": "2020-10-13T14:38:54.980Z",
    "created_at": "2020-10-13T14:38:53.595Z",
    "updated_at": "2020-10-13T14:38:55.003Z"
  },
  {
    "id": 6,
    "title": "Ich habe bei meinem Vorstellungsbesuch etwas verloren. An wen kann ich mich wenden?",
    "sorting": 6,
    "description": "Bitte informieren Sie während der Pause oder nach der Vorstellung unseren Revisionsdienst im Foyer rechts der Feststiege. Sollten Sie den Verlust erst später bemerken, kontaktieren Sie bitte die Betriebsfeuerwehr der Wiener Staatsoper: Tel. +43 1 51444/2415 oder feuerwehr@wiener-staatsoper.at",
    "language": "DE",
    "published_at": "2020-10-13T14:39:26.872Z",
    "created_at": "2020-10-13T14:39:17.532Z",
    "updated_at": "2020-10-13T14:39:26.911Z"
  },
  {
    "id": 7,
    "title": "Kann ich Vorstellungen der Wiener Staatsoper auch live zu Hause erleben?",
    "sorting": 7,
    "description": "Auf play.wiener-staatsoper.at übertragen wir regelmäßig kostenlose Live-Streams aus der Wiener Staatsoper. \nDas Live-Stream-Angebot kann mit Windows-PCs und Apple Computern per Webbrowser genutzt werden. Für die mobilen Plattformen von Apple und Android laden Sie bitte die eigens dafür bereitgestellten Apps aus den App-Stores. Besitzerinnen und Besitzern von Smart-TV Hardware (Apple TV, Google Chromecast, Amazon Fire TV) bieten wir ebenfalls Unterstützung an. Unser Live-Angebot steht Ihnen maximal 72 Stunden nach Beginn zur Verfügung. ",
    "language": "DE",
    "published_at": "2020-10-13T14:40:34.322Z",
    "created_at": "2020-10-13T14:39:53.751Z",
    "updated_at": "2022-09-22T12:46:34.157Z"
  },
  {
    "id": 19,
    "title": "Welche Ermäßigungen gibt es? ",
    "sorting": 8,
    "description": "- Stehplatzkarten: ab 13€ \n- Karten für Kurzentschlossene (für BundestheaterCard-Besitzer*innen): 49€ \n- Ballett-Bonus: 15%-Ermäßigung für Ballett-Bonus-Besitzer*innen (25€ pro Saison) \n- Generalproben für U27: 10€ \n- Karten für gekennzeichnete Produktionen für U27: 15€ bzw. 20€ \n- Gratis Eintritt zu Einführungsmatineen für Newsletter-Abonnent*innen, Abonnent*innen, Zyklen-Besitzer*innen, U27, Freundeskreis-Mitglieder \n- Opernkarten für Kinder: 15€ \n- Ö1: 10% auf ausgewählte Vorstellungen \n- Für Kulturpass-Inhaber*innen kostenlose Karten \nDetaillierte Informationen finden Sie unter: wiener-staatsoper.at/service/karten-abos-zyklen/",
    "language": "DE",
    "published_at": "2022-11-23T15:09:51.640Z",
    "created_at": "2022-11-23T15:09:43.801Z",
    "updated_at": "2022-11-23T15:09:51.651Z"
  }
]

export const en = [
  {
    "id": 8,
    "title": "Will I be admitted in after the performance has begun?",
    "sorting": 1,
    "description": "The performance starts on time. Please note that after the start of the performance, the auditorium (including the boxes) may only be entered again during the intermission. This applies to both late-arriving guests and those leaving the auditorium during the performance. As this also holds for the end of the intermission, please return to your seat in good time. For latecomers, we provide a live screening at the bar on »Parkett« level, or at the Galerie.",
    "language": "EN",
    "published_at": "2020-10-13T14:41:07.759Z",
    "created_at": "2020-10-13T14:41:04.883Z",
    "updated_at": "2023-11-08T13:41:44.991Z"
  },
  {
    "id": 16,
    "title": "Where can I find the buffets? ",
    "sorting": 1,
    "description": "Buffets are available for you in the following rooms: \n- Gustav Mahler Hall \n- Marble Hall \n- Gallery Break Buffet\n- Schwindfoyer & Schwindloggia \n- Buffets in the Parterrelogenrang\n- Foyer in the stalls\nTo shorten waiting times, you can pre-order food and drinks in person or up to two hours before the performance starts via www.staatsoper.gerstner.at.",
    "language": "EN",
    "published_at": "2021-08-30T09:26:53.336Z",
    "created_at": "2021-06-17T10:13:14.787Z",
    "updated_at": "2023-05-15T09:54:15.557Z"
  },
  {
    "id": 9,
    "title": "Am I allowed to shoot photos or videos?",
    "sorting": 2,
    "description": "For reasons of copyright, it is not allowed to make photographic, audio or video recordings of performances. You may of course take pictures for private purposes in the lounge rooms and bars as well as before and after the performance. Any recordings made for commercial and journalistic purposes always require approval by the press office.",
    "language": "EN",
    "published_at": "2020-10-13T14:42:13.789Z",
    "created_at": "2020-10-13T14:41:27.173Z",
    "updated_at": "2020-10-13T14:42:13.903Z"
  },
  {
    "id": 10,
    "title": "Where can I buy programme booklets?",
    "sorting": 3,
    "description": "Our program booklets with comprehensive information on the composer and the work are available in English from the ushers (price € 5,80) or as a digital version under »www.issuu.com/wienerstaatsoper« (price € 2,50).",
    "language": "EN",
    "published_at": "2020-10-13T14:42:09.683Z",
    "created_at": "2020-10-13T14:42:06.980Z",
    "updated_at": "2023-10-16T14:38:35.484Z"
  },
  {
    "id": 12,
    "title": "Where can I rent an opera glass?",
    "sorting": 5,
    "description": "You can rent an opera glass at the cloakrooms for € 2 (subject to availability).",
    "language": "EN",
    "published_at": "2020-10-13T14:43:02.969Z",
    "created_at": "2020-10-13T14:43:01.386Z",
    "updated_at": "2020-10-13T15:29:59.643Z"
  },
  {
    "id": 13,
    "title": "I lost something while attending a performance. Who can I turn to?",
    "sorting": 5,
    "description": "Please get in touch with our supervisory staff during the interval or after the performance at their offices in the foyer on the right-hand side of the grand staircase.\nShould you fail to notice your loss until later, please contact our in-house fire service: tel. +43-1-51444-2415 or feuerwehr@wiener-staatsoper.at.\n",
    "language": "EN",
    "published_at": "2020-10-13T14:43:29.582Z",
    "created_at": "2020-10-13T14:43:27.793Z",
    "updated_at": "2021-06-17T09:44:22.805Z"
  },
  {
    "id": 14,
    "title": "Can I also watch performances of Wiener Staatsoper live at home?",
    "sorting": 7,
    "description": "We regularly broadcast live streams from the Vienna State Opera on play-wiener-staatsoper.at for free.\nYou can reach our live stream through Windows PCs and Apple computers via a web browser. For the mobile platforms from Apple and Android, please download the specially provided apps from the app stores. We also offer support to owners of Smart TV hardware (Apple TV, Google Chromecast, Amazon Fire TV). Our live offer is available to you a maximum of 72 hours after it begins. ",
    "language": "EN",
    "published_at": "2020-10-13T14:43:57.849Z",
    "created_at": "2020-10-13T14:43:56.027Z",
    "updated_at": "2022-09-22T12:46:43.466Z"
  }
]