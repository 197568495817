<template>
  <div class="news container-full-height layout-with-nav">
    <div :class="{ 'content-wrapper content-wrapper-loading': true, loaded }">
      <div class="page-title">
        <h1 class="titleFont">{{ 'news_insights' | translate }}</h1>
      </div>
      <news-list :items="newsSorted" v-if="loaded"></news-list>
    </div>
    <base-nav></base-nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NewsList from '@/components/NewsList.vue'
import { getNews } from '../store/modules/static-news'

const newsSorting = (a, b) => {
  return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime()
}

export default {
  components: { NewsList },
  data() {
    return {
      loaded: false,
    }
  },
  watch: {
    locale() {
      this.loadNews()
    },
  },
  computed: {
    ...mapState('app', ['locale', 'news', 'config']),
    newsSorted() {
      const news = this.news.filter(i => i.language === this.locale)
      news.sort((a, b) => a.Sorting - b.Sorting)
      return news
    },
  },
  methods: {
    async loadNews() {
      this.loaded = false

      const news = getNews()

      if (Array.isArray(news) && news.length > 0) {
        news.sort(newsSorting)
      }

      this.$store.commit('app/UPDATE_NEWS', news)
      this.loaded = true
    },
  },
  async created() {
    this.loadNews()
  },
}
</script>

<style>
</style>
