<template>
  <div class="layout-with-nav black-bkg">
    <article class="container faq-page-container">
      <h3 class="text-uppercase headline">{{ 'faq_headline' | translate }}</h3>
      <div class="faq-content-text text-center">
        <div
          class="faq-item"
          v-for="(item, idx) in items"
          :key="idx"
          :class="{ active: item.active }"
          @click.stop="setActive(item)"
        >
          <div class="faq-item__header" v-html="item.title"></div>
          <div class="faq-item__body" v-html="$options.filters.toHTML(item.description)"></div>
        </div>
      </div>
    </article>
    <base-nav />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as faqs from '../store/modules/static-faqs.js'

export default {
  data() {
    return {
      items: []
    }
  },
  computed: {
    ...mapState('app', ['locale', 'config']),
  },
  watch: {
    locale() {
      this.reloadContent()
    }
  },
  methods: {
    setActive(item) {
      const newState = !item.active
      this.items.forEach((i) => {
        i.active = false
      })
      item.active = newState
      event.target.scrollIntoView()
    },
    async reloadContent() {
      let mappedFaqs = faqs[this.locale.toLowerCase()].map(i => {
        i.active = false
        return i
      })
      this.items = mappedFaqs
    }
  },
  created() {
    this.reloadContent()
  }
}
</script>

<style scoped>
.faq-page-container {
  padding: 0;
}
.faq-page-container * {
  user-select: none;
}

h3 {
  margin: 12px 22px 0 22px;
  border-bottom: 1px solid #ccc;
  font-family: 'GT Super Vienna Display';
  padding-bottom: 14px;
}
.faq-content-text {
  font-family: 'GT Super Vienna Display';
  padding-top: 12px;
  overflow-y: auto;
  height: calc(600px - 90px);
}
.faq-content-text::-webkit-scrollbar {
  display: none;
}
.faq-item__header {
  line-height: 36px;
  font-size: 28px;
  text-align: left;
  padding: 5px 28px 5px 32px;
  position: relative;
}

.faq-item__body {
  padding-left: 58px;
  font-size: 24px;
  line-height: 34px;
  text-align: left;
  height: 0;
  overflow: hidden;
}

.faq-item {
  position: relative;
  padding-top: 7px;
  padding-bottom: 14px;
  margin: 0 22px;
  border-bottom: 1px solid #ccc;
}
.faq-item:before {
  content: '';
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  padding: 8px;
  display: block;
  font-size: 28px;
  left: 4px;
  top: 16px;
  position: absolute;
}
.faq-item.active:before {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 24px;
}

.faq-item.active .faq-item__body {
  height: auto;
  padding: 16px 58px;
  font-size: 28px;
  line-height: 35px;
  padding: 0 32px;
}
.faq-item.active .faq-item__body p {
  margin-left: 0 !important;
}
.faq-item .faq-item__header {
  padding-bottom: 0;
}
</style>
