<template>
  <g>
    <path
      :stroke="stroke" fill="none" stroke-miterlimit="10" stroke-width="2px"
      d="M35.14,6a19.86,19.86,0,0,1,0,28.17,20.06,20.06,0,0,1-28.28,0A19.86,19.86,0,0,1,6.86,6"
    />
    <line :stroke="stroke" fill="none" stroke-miterlimit="10" stroke-width="2px" x1="21" y1="20" x2="21" />
  </g>
</template>

<script>
export default {
  props: {
    stroke: {
      type: String,
      default: 'white'
    }
  }
}

</script>