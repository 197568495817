<template>
  <div class="home container-full-height layout-with-nav">
    <div class="content-wrapper">
      <div class="header">
        <dynamic-logo></dynamic-logo>
      </div>
      <div class="info-wrapper" :style="`background-image: url(${production.headerImage})`">
        <div class="title" v-html="production.title"></div>
        <button @click.stop="$router.push('/production-detail')" class="noselect">{{ 'it_more_info' | translate }}</button>
        <gastro-button v-if="gastroActive.status" @click.native.stop="$router.push('/gastro')"></gastro-button>
      </div>
      <ul>
        <li @click.stop="$router.push('/news')">
          <span>{{ 'it_insights' | translate }}</span>
        </li>
        <li @click.stop="$router.push('/about')">
          <span>{{ 'it_about' | translate }}</span>
        </li>
        <li @click.stop="$router.push('/newsletter')">
          <span>{{ 'it_newsletter' | translate }}</span>
        </li>
        <li @click.stop="$router.push('/faq')">
          <span>{{ 'it_faq_short' | translate }}</span>
        </li>
      </ul>
    </div>
    <base-nav></base-nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GastroButton from '@/components/GastroButton.vue'
import DynamicLogo from '@/components/DynamicLogo.vue'

export default {
  name: 'home',
  components: { GastroButton, DynamicLogo },
  computed: {
    ...mapState('app', ['production', 'gastroActive']),
  },
  created() {
    this.$store.dispatch('app/initUnit')
  },
}
</script>

<style scoped>
.content-wrapper {
  display: block;
  height: 100%;
  overflow: hidden;
}

.header {
  padding-left: 14px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
  height: 86px;
}
.info-wrapper {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  height: 428px;
}
.title {
  position: absolute;
  bottom: 192px;
  width: 100%;
  padding: 0 80px;
  font-family: 'GT Super Vienna Display';
  font-size: 58px;
  line-height: 54px;
  user-select: none;
}
button {
  width: 260px;
  font-family: 'GT Super Vienna Text Book';
  border: solid 1px #ccc;
  font-size: 18px;
  line-height: 33px;
  color: white;
  border-radius: 50px;
  background-color: black;
  text-transform: uppercase;
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
}
ul {
  list-style: none;
  width: 955px;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
}
li {
  font-size: 29px;
  text-transform: uppercase;
  font-family: 'GT Super Vienna Display';
  width: 25%;
  text-align: center;
  padding-bottom: 26px;
  padding-top: 25px;
  border-right: 1px solid #ccc;
}
li:last-of-type {
  border: none;
}
</style>
