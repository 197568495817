<template>
  <article class="content-slide full-height item-slider-wrapper">
    <h3 class="text-uppercase">
      {{ 'det_dates' | translate }}
    </h3>
    <div class="item-slider" v-show="loaded">
      <div class="arrow arrow-prev" :class="{ active: sliderInfo.navCurrentIndex !== 0}" @click.stop="prev()" v-show="multiplePages">&larr;</div>
      <div class="items">
        <div class="item-outer" v-for="(item, idx) in items" :key="idx">
          <div class="item">
            <div class="date" v-html="$options.filters.fmtdate(item.date, 'monthname')"></div>
            <div class="dayname" v-html="$options.filters.dayname(item.date)"></div>
            <div class="time"> {{ item.time }} {{ 'it_uhr' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="arrow arrow-next" :class="{ active: sliderInfo.navCurrentIndex !== sliderInfo.pages - 1}" @click.stop="next()" v-show="multiplePages">
        &rarr;
      </div>
    </div>
    <div class="available-as-stream-info" style="position: relative; top: 40px;">
      <!-- <span v-html="$t('available_as_stream')"></span> -->
      <!-- <div style="display: inline-block; font-size: 24px; margin-right: 20px">
        {{ 'prescreen.production' | translate }}<br>{{'prescreen.supported_by' | translate}}
      </div> 
      <img :src="`/img/philoro_sw.png`" height=60 style="position: relative; top:10px;" /> -->
    </div>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slider: null,
      loaded: false,
      sliderInfo: {
        pages: 0,
        index: 0,
        slideItems: [],
        navCurrentIndex: 0,
        displayIndex: 0,
      },
    }
  },
  computed: {
    ...mapState('app', ['config']),
    multiplePages() {
      return this.items.length > 3
    },
  },
  methods: {
    next() {
      this.slider.goTo('next')
    },
    prev() {
      this.slider.goTo('prev')
    },
    updateSliderInfo() {
      const info = this.slider.getInfo()
      Object.assign(this.sliderInfo, info)
    },
    initSlides() {
      if (!this.items.length && !this.slider) {
        setTimeout(() => {
          this.initSlides()
        }, 300)
        return
      }
      this.loaded = true
      if (this.slider) {
        this.slider.refresh()
      } else {
        this.slider = tns({
          container: '.items',
          items: 3,
          controls: false,
          speed: 300,
          mouseDrag: false,
          touch: true,
          loop: false,
          swipeAngle: 15,
          slideBy: 3,
        })
      }

      Object.assign(this.sliderInfo, this.slider.getInfo())
      this.slider.events.on('indexChanged', this.updateSliderInfo.bind(this))
    },
  },
  mounted() {
    setTimeout(() => {
      this.initSlides()
    }, 300)
  },
}
</script>

<style scoped>
.item-slider-wrapper {
  position: relative;
}

.item-slider {
  width: 922px;
  margin: 0 auto;
  position: relative;
}

.item-slider .arrow {
  position: absolute;
  top: 50%;
  font-size: 60px;
  z-index: 10;
  color: #333;
  transform: translateY(-50%);
}
.item-slider .arrow.active {
  color: #ccc;
}


.item-slider .arrow-next {
  right: 0px;
}
.item-slider .arrow-prev {
  left: 0px;
}

.items {
  width: 780px;
}

.item-outer {
  padding: 0 8px;
}

.item {
  display: block;
  height: 280px;
  padding: 60px 0;
  text-align: center;
  background: black;

  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-family: 'GT Super Vienna Display';
  font-size: 30px;
}
.item .date {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 40px;
}

.item .dayname {
  font-size: 30px;
  line-height: 40px;
}
.item .time {
  font-size: 30px;
  line-height: 40px;
  display: inline-block;
}

.available-as-stream-info {
  font-size: 30px;
  margin: 0 auto;
  padding: 40px 0 0 0;
}

.available-as-stream-info i {
  white-space: nowrap;
  
  font-family: 'GT Super Vienna Display Italic';
}
</style>
