<template>
  <div class="title-slide" >
    <div class="inner" :style="`background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(${item.headerImage})`">
      <div class="page-title">
        <h1 class="titleFont">{{ title }}</h1>
      </div>
      <div class="text-container full-width-content full-width-content--centered">
        <h1 class="title" v-html="item.title"></h1>
        <span class="subtitle" v-html="item.overallTitle"></span>

        <div class="break-info" v-if="startEndTimes">
          <label>{{ 'all_start' | translate }}</label>
          <span
            >{{ item.timeFromDisplay }}
            {{ 'it_uhr' | translate }}</span
          ><br>
          <label>{{ 'all_end' | translate }}</label>
          <span
            >{{ item.timeToDisplay }} {{ 'it_uhr' | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: Object,
    title: String,
    startEndTimes: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('app', ['config']),
  },
}
</script>

<style scoped>
.title-slide {
  height: 600px;
  background-size: cover;
}
.title-slide h1 {
  margin: 0;
  font-weight: normal;
}

.title-slide .inner {
  background-color: black;
  height: 100%;
  position: relative;
  padding: 0 32px;
}

.title-slide .subtitle {
  font-family: 'GT Super Vienna Display';
  display: inline-block;
  font-size: 30px;
  line-height: 30px;
  align-self: end;
  margin: 30px 0;
}

.title-slide .title {
  font-family: 'GT Super Vienna Display';
  font-size: 60px;
  line-height: 53px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}


.break-info {
  font-size: 30px;
  font-family: 'GT Super Vienna Display';
  margin: 60px 0 0 0;
}

.break-info label {
  font-family: 'GT Super Vienna Display Italic';
  display: inline-block;
  margin-right: 8px;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
