<template>
  <div
    :class="{ 'next-slide-arrow-wrapper': true, slidenavActive }"
    @click="handleClick"
  >
    <base-icon width="60" height="24" viewBox="0 0 40 16">
      <polygon
        class="a"
        points="40 1.23 40 0 20 14.77 0 0 0 1.23 20 16 40 1.23"
      />
    </base-icon>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['slidenavActive']),
  },
  methods: {
    handleClick() {
      this.$emit('event')
    },
  },
}
</script>

<style scoped>
.next-slide-arrow-wrapper {
  position: fixed;
  bottom: 44px;
  left: 447px;
  z-index: 1000;
  width: 60px;
  height: 24px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.next-slide-arrow-wrapper.slidenavActive {
  opacity: 1;
}
</style>
