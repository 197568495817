import Vue from 'vue'
import axios from 'axios'

export const namespaced = true

let BASE_URL =  `http://${window.location.hostname}`
if (process.env.VUE_APP_CUSTOM_BASE_URL !== "no") {
  BASE_URL = process.env.VUE_APP_CUSTOM_BASE_URL
}

// import { getProduction } from './static-production.js'

export const state = {
  activated: false,
  disabled: false,
  locale: 'de',
  slidenavActive: false,
  timeRemaining: -1,
  subtiteling_active: false,
  surveyStopped: false,
  config: {
    // eslint-disable-next-line no-undef
    DATA_API: BASE_URL + process.env.VUE_APP_DATA_API,
    // eslint-disable-next-line no-undef
    STATIC_IMAGES: BASE_URL + process.env.VUE_APP_STATIC_IMAGES,
    // eslint-disable-next-line no-undef
    STREAM_SOURCE: BASE_URL + process.env.VUE_APP_STREAM_SOURCE,
    // eslint-disable-next-line no-undef
    STATS_API: BASE_URL + process.env.VUE_APP_STATS_API,
    // eslint-disable-next-line no-undef
    STATS_API_REST: BASE_URL + process.env.VUE_APP_STATS_API_REST,
    // eslint-disable-next-line no-undef
    CMS_API: BASE_URL + process.env.VUE_APP_CMS_API,
    // eslint-disable-next-line no-undef
    IS_PREVIEW_BUILD: process.env.VUE_APP_PREVIEW_BUILD === 'yes',
  },
  timerInterval: null,
  // production: {},
  // production: getProduction(),
  
  news: [],
  showAlert: true,
  timerOffAction: 'switch',
  unit: {
    displayName: '',
    id: '',
    ip: '',
  },
  gastroActive: {
    status: false,
    reason: '',
  },
  specialWelcome: {
    status: false,
    reason: '',
  },
  answeredSurveyQuestions: [],
  showBlockNotification: false,
  blockNotificationText: 'blocknotification.default',
  blockNotificationIcon: 'stop',
  blockNotificationType: 'custom',
  showITBlockMessage: false,
}

export const mutations = {
  TURN_ON_OFF(state, value) {
    state.activated = value
  },
  CHANGE_LOCALE(state, value) {
    state.locale = value
  },
  SET_SLIDENAV_ACTIVE(state, value) {
    state.slidenavActive = value
  },
  ACTIVATE_INFOTAINMENT(state) {
    state.disabled = false
  },
  DEACTIVATE_INFOTAINMENT(state) {
    state.disabled = true
  },
  START_SUBTITELING(state) {
    state.subtiteling_active = true
  },
  SET_TIMER(state, { seconds, type = 'switch' } = {}) {
    if (state.timerInterval) {
      clearInterval(state.timerInterval)
    }

    if (seconds < 0) {
      state.timeRemaining = -1
      state.timerOffAction = ''
      return
    }

    state.timeRemaining = seconds
    state.timerOffAction = type

    state.timerInterval = setInterval(() => {
      state.timeRemaining -= 1
      if (state.timeRemaining < 0) {
        clearInterval(state.timerInterval)
      }
    }, 1000)
  },
  CANCEL_TIMER(state) {
    if (state.timerInterval) {
      clearInterval(state.timerInterval)
    }
    state.timeRemaining = -1
    state.timerOffAction = ''
  },
  UPDATE_PRODUCTION(state, production = {}) {
    Vue.set(state, 'production', production)
  },
  UPDATE_NEWS(state, news = []) {
    Vue.set(state, 'news', news)
  },
  SET_SHOW_ALERT(state, value = true) {
    state.showAlert = value
  },
  SET_GASTRO_ACTIVE(state, gastroActive = {}) {
    state.gastroActive = {
      ...state.gastroActive,
      ...gastroActive,
    }
  },
  SET_SPECIALWELCOME_ACTIVE(state, specialWelcome = {}) {
    state.specialWelcome = {
      ...state.specialWelcome,
      ...specialWelcome,
    }
  },
  SET_UNIT(state, unit = {}) {
    state.unit = {
      ...state.unit,
      ...unit,
    }
  },
  SET_SURVEY_STOPPED(state, isStopped=true) {
    state.surveyStopped = isStopped
  },
  SET_ANSWERED_QUESTIONS(state, answered=[]) {
    state.answeredSurveyQuestions = answered;
  },
  SET_SHOW_BLOCK_NOTIFICATION(state, show=false) {
    state.showBlockNotification = show;
  },
  SET_BLOCK_NOTIFICATION_TEXT(state, text='blocknotification.default') {
    state.blockNotificationText = text;
  },
  SET_BLOCK_NOTIFICATION_ICON(state, icon='stop') {
    if (['stop', 'alert'].indexOf(icon) !== -1) {
      state.blockNotificationIcon = icon
    }
  },
  SET_SHOW_IT_BLOCKMESSAGE(state, show=false) {
    state.showITBlockMessage = show;
  }
}

export const actions = {
  async initUnit({ state, commit }) {
    try {
      const { data: status } = await axios.get(
        `${state.config.DATA_API}/unit/status`
      )

      const { gastroActive, specialWelcome, seat, infotainmentBlocked } = status

      if (gastroActive) {
        commit('SET_GASTRO_ACTIVE', gastroActive)
      }

      if (specialWelcome && specialWelcome.status === true) {
        commit('SET_SPECIALWELCOME_ACTIVE', specialWelcome)
      }

      if (infotainmentBlocked && infotainmentBlocked.status === true) {
        commit('SET_SHOW_IT_BLOCKMESSAGE', true)
      }

      if (seat) {
        commit('SET_UNIT', seat)
      }
    } catch (e) {
      // console.log(e)
      // noop
    }
  },
  async getOrderStatus({ state }) {
    try {
      const { data: status } = await axios.get(
        `${state.config.DATA_API}/unit/order`
      )

      return status
    } catch (e) {
      // console.log(e)
      // noop
    }
  },
}
