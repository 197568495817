<template>
  <div class="item-slider-wrapper">
    <div class="item-slider" v-show="loaded">
      <div class="arrow arrow-prev" :class="{active: sliderInfo.index > 0}" @click.stop="prev()">
        <div class="arrows">&larr;</div>
      </div>
      <div class="items">
        <div
          class="item-outer"
          v-for="item in items"
          :key="item.id"
          @click.stop="$router.push(`/news/${item.id}`)"
        >
          <div class="item">
            <div
              class="image"
              :style="
                `background-image: url(${
                  item.teaserImage
                })`
              "
            ></div>
            <div
              class="surtitle"
              v-html="item.overallTitle || 'Musiktheater Linz'"
              v-line-clamp:24="2"
            ></div>
            <div class="title" v-html="item.title" v-line-clamp:34="3"></div>
            <div
              class="date"
              v-html="$options.filters.fmtFromTo(item.dateFrom, item.dateTo)"
            ></div>
          </div>
        </div>
      </div>
      <div class="arrow arrow-next" :class="{active: sliderInfo.index < sliderInfo.pages-1}" @click.stop="next()">
        <div class="arrows">&rarr;</div>
      </div>
    </div>
    <div class="dots">
      <div
        :class="{
          dot: false,
          active: idx === sliderInfo.navCurrentIndex,
        }"
        v-for="(i, idx) in sliderInfo.pages"
        :key="idx"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slider: null,
      loaded: false,
      sliderInfo: {
        pages: 0,
        index: 0,
        slideItems: [],
        navCurrentIndex: 0,
        displayIndex: 0,
      },
    }
  },
  computed: {
    ...mapState('app', ['config']),
  },
  methods: {
    next() {
      this.slider.goTo('next')
    },
    prev() {
      this.slider.goTo('prev')
    },
    updateSliderInfo() {
      const info = this.slider.getInfo()
      Object.assign(this.sliderInfo, info)
    },
    initSlides() {
      this.loaded = true
      if (this.slider) {
        this.slider.refresh()
      } else {
        this.slider = tns({
          container: '.items',
          items: 2,
          controls: false,
          speed: 300,
          mouseDrag: false,
          touch: true,
          loop: false,
          swipeAngle: 15,
          slideBy: 2,
        })
      }

      Object.assign(this.sliderInfo, this.slider.getInfo())
      this.slider.events.on('indexChanged', this.updateSliderInfo.bind(this))
    },
  },
  mounted() {
    setTimeout(() => {
      this.initSlides()
    }, 300)
  },
}
</script>

<style scoped>
.item-slider-wrapper {
  position: relative;
}

.item-slider {
  width: 780px;
  margin: 0 auto;
  position: relative;
}

.item-slider .arrow {
  position: absolute;
  top: 50%;
  opacity: 0.3;
  transform: translateY(-50%);
  font-size: 60px;
  font-family: 'GT Super Vienna Display';
  color: #ccc;
}
.item-slider .arrow.active {
  opacity: 1;
}
.item-slider .arrow-next {
  right: -68px;
}
.item-slider .arrow-prev {
  left: -70px;
}

.items {
  width: 780px;
}

.item-outer {
  width: 388px;
  padding: 0 7px;
}

.item {
  display: block;
  height: 519px;
  text-align: center;
  background: black;
  border: 1px solid #ccc;
  position: relative;
}
.item .image {
    background-size: cover;
    background-position: center center;
    height: 259px;
}

.item .surtitle {
    font-family: 'GT Super Vienna Display Italic';
    word-break: break-word !important;
    height: 48px;
    font-size: 21px;
    padding: 16px 16px;
    line-height: 24px;
}

.item .title {
    position: absolute;
    bottom: 70px;
    padding: 0 16px;
    height: 117px;
    line-height: 39px;
    font-size: 42px;
    font-family: 'GT Super Vienna Display';
    word-break: break-word !important;
    text-transform: uppercase;
}

.item .date {
    font-family: 'GT Super Vienna Display';
    bottom: 10px;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 34px;
    font-size: 22px;
    height: 34px;
}
</style>
