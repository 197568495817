import Vue from 'vue'
import moment from 'moment'

moment.locale('de')

const fmtdate = {
  long: 'dd, DD.MM.YYYY HH:mm',
  short: 'DD.MM.YYYY',
  monthname: 'D, MMMM YYYY',
}

Vue.filter('fmtdate', (value, type = 'long') => {
  return value ? moment(value).format(fmtdate[type] || fmtdate['long']) : ''
})

Vue.filter('fmtday', value => {
  return value ? moment(value).format('DD') : ''
})

Vue.filter('monthname', value => {
  return value ? moment(value).format('MMMM') : ''
})

Vue.filter('dayname', value => {
  return value ? moment(value).format('dddd') : ''
})

Vue.filter('fmttime', value => {
  return value ? moment(value).format('HH:mm') : ''
})

Vue.filter('fmtPrice', value => {
  return (`€ ` + (value / 100).toFixed(2)).replace('.', ',')
})

Vue.filter('fmtFromTo', (dateFrom, dateTo) => {
  const mf = moment(dateFrom)
  const mt = moment(dateTo)

  if (mf.diff(mt, 'days') === 0) {
    return mf.format('D.M.YYYY')
  }

  return `${mf.format('D.M.')} &ndash; ${mt.format('D.M.YYYY')}`
})

Vue.filter('toHTML', value => {
  return value.replace(/\n/gi, '<br>')
})
