<template>
  <div class="survey-container" v-if="!surveyStopped">
    <div class="intro" v-if="step == 0">
      <h3 v-html="$t('survey.headline')"></h3>
      <p v-html="$t('survey.intro', { count: questions.length })"></p>
      
      <button class="btn-default" @click="step = 1">{{ 'survey.start' | translate }}</button><br>
      <button class="btn-back" @click.stop="stopSurvey()">{{ 'survey.cancel' | translate }}</button>
    </div>

    <div class="question" v-if="step == 1 && !allAnswered">
      <p v-html="actQuestion.question"></p>
      <svg v-for="i in 5" :key="`rate-${i}`" @click.stop="vote(i)" 
        width="80" height="80" viewBox="0 0 64 64"
        xmlns:cc="http://web.resource.org/cc/" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" version="1.0">
        <g>
          <path 
            :fill="i <= actQuestion.value ? '#fff' : 'transparent'" fill-opacity="1" stroke="#e1e1e1"
            stroke-width="2" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"
            d="M46.3 52l-14.4-9.5-14.4 9.4L22 35.3 8.7 24.5l17.1-.9 6.2-16 6 16 17.2 1-13.4 10.7 4.5 16.6z" transform="matrix(.98686 0 0 1.03704 .5 1.2)"/>
        </g>
      </svg>
    </div>

    <div class="outro" v-if="allAnswered">
      <h3 v-html="$t('survey.outro_headline')"></h3>
      <p v-html="$t('survey.outro_text', { count: questions.length })"></p>
      <button class="btn-back" @click.stop="stopSurvey()">&larr; {{ 'survey.back' | translate }}</button>
    </div>

  </div>
</template>

<script>
import { de, en } from '@/data/survey.js'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      DE: [],
      EN: [],
      idx: 0,
      step: 0,
      answered: [],
    }
  },
  computed: {
    ...mapState('app', ['config', 'locale', 'surveyStopped', 'answeredSurveyQuestions']),
    questions() {
      return this.locale === 'en' ? this.EN : this.DE
    },
    actQuestion() {
      const idx = this.idx >= this.questions.length ? this.questions.length - 1 : this.idx
      return this.questions[this.idx]
    },
    allAnswered() {
      return this.idx == this.questions.length
    }
  },
  methods: {
    vote(value = 0) {
      this.actQuestion.value = value
      this.answered.push(this.actQuestion.key);
      this.$store.commit('app/SET_ANSWERED_QUESTIONS', this.answered)
      this.next()
      try {
        const URI = `${this.config.STATS_API_REST}/track/event`;
        this.$axios.post(URI, { 
          category : "survey",
          action: this.actQuestion.key,
          label: `${value} Stars`
        });
      } catch (e) { 
        //noop 
      }
    },
    _next(){
      this.idx = this.idx + 1;  
    },
    stopSurvey() {
      this.$store.commit('app/SET_SURVEY_STOPPED', true)
    }
  },
  mounted() {
    this.DE = de.map((i) => {
      i.value = 0
      return i
    }).filter(i => {
      return this.answeredSurveyQuestions.indexOf(i.key) === -1
    })

    this.EN = en.map((i) => {
      i.value = 0
      return i
    }).filter(i => {
      return this.answeredSurveyQuestions.indexOf(i.key) === -1
    })

    if (this.allAnswered) {
      return this.stopSurvey()
    }

    this.answered = this.answeredSurveyQuestions.map(i => i);
  },
  created() {
    this.next = debounce(this._next.bind(this), 1000);
  }
}
</script>

<style scoped>
.survey-container {
  position: fixed;
  width: 952px;
  height: 600px;
  top: 0;
  left: 0;
  z-index: 400;
  background: rgba(0, 0, 0, 0.9);
  padding: 0;
  font-size: 33px;
  text-align: center;
}
.survey-container .intro,
.survey-container .outro,
.survey-container button {
  margin: 50px 0;
}

.survey-container > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.survey-container h3 {
  font-family: 'GT Super Vienna Display';
  text-transform: uppercase;
  font-size: 50px;
}

.survey-container .btn-default {
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 18px;
  color: black;
  padding: 5px 15px;
  font-family: "GT Super Display";
  font-size: 19px;
  text-transform: uppercase;
  background-color: white;
}
.survey-container .btn-default:focus {
  outline: none;
}


.survey-container .btn-back {
  user-select: none;
  border: none;
  color: white;
  padding: 5px 15px;
  font-family: "GT Super Display";
  font-size: 19px;
  text-transform: uppercase;
  background-color: transparent;
}
.survey-container .btn-back:focus {
  outline: none;
}

.survey-container .question p {
  margin-bottom: 30px;
}
</style>