<template>
  <div class="image-slide content-slide full-height" :style="`background-image: url(${backgroundImage})`">
  </div>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('app', ['config']),
    backgroundImage() {
      if (this.slide.image) {
        const fullsizeImg = this.slide.image.url
        return get(this.slide,'image.formats.large.url', fullsizeImg)
      }

      return '/images/main-hall.jpg'
    }
  }
}
</script>

<style scoped>
.image-slide {
  background-size: cover;
}
</style>