import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import axios from 'axios'
import '@/assets/vendor_css/tiny-slider.css'

// eslint-disable-next-line no-unused-vars
import filter from '@/filters/index.js'

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  container: '.scrollable-slides',
  duration: 300,
  easing: 'ease-in-out',
})

import lineClamp from 'vue-line-clamp'
Vue.use(lineClamp, {
  lines: 3,
})

import { Plugin } from 'vue-fragment'
Vue.use(Plugin)

let t = null

function handleSlideNavClick() {
  if (t) {
    clearTimeout(t)
  }
  this.context.$store.commit('app/SET_SLIDENAV_ACTIVE', true)
  t = setTimeout(() => {
    this.context.$store.commit('app/SET_SLIDENAV_ACTIVE', false)
  }, 2000)
}

Vue.directive('scrolling-nav', {
  bind(el, binding, vnode) {
    el.dataset.scrollnav = 'enabled'

    el.addEventListener('click', handleSlideNavClick.bind(vnode))
  },
  unbind(el) {
    el.removeEventListener('click', handleSlideNavClick)
  },
})

// eslint-disable-next-line no-undef
const requireComponent = require.context(
  './components/base',
  true,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  next()

  try {

    if (process.env !== 'production') return
    const { fullPath, name, path } = to
    // eslint-disable-next-line no-undef
    const STATS_API = process.env.VUE_APP_STATS_API_REST
    axios.post(`${STATS_API}/track/pageview`, {
      to: {
        fullPath,
        name,
        path,
      },
    })
  } catch (e) {
    //noop
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
