<template>
  <article class="content-slide">
    <h3 class="titleFont">{{ slide.slideTitle }}</h3>
    <div class="content-text text-center" v-html="$options.filters.toHTML(slide.text)"></div>
  </article>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
h3.titleFont {
  padding-top: 20px;
}

.content-text {
  padding-top: 50px;
  font-size: 30px;
  line-height: 35px;
  padding: 60px 20px 0 20px;
}
.content-text.pt0 {
  padding-top: 0;
}

.content-text p {
  font-size: 30px;
  line-height: 35px;
}
</style>