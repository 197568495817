<template>
  <div class="about container-full-height layout-with-nav">

    <div ref="scrollContainer" class="content-wrapper content-wrapper-loading" :class="{ loaded }" style="overflow-y: auto;">
      <component :data-navgroup="idx+1" :slide="slide" :is="slide.type || slide.__component" v-for="(slide, idx) of slides" :key="`${slide.id}_${idx}_${locale}`"></component>
    </div>
    <base-nav>
      <div class="dots__dotwrapper">
        <div class="dot" @click.stop="scrollTo(idx)" :class="{ active : idx === selectedIdx } " v-for="(dot, idx) in 4" :key="idx"></div>
      </div>
    </base-nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ScrolltoNextslide from '@/components/ScrolltoNextslide.vue'
import SponsorSlide from '@/components/slides/SponsorSlide.vue'
import ContentSlide from '@/components/slides/ContentSlideAutoHeight.vue'
import TitleSlide from '@/components/slides/TitleSlide.vue'
import ImageSlide from '@/components/slides/ImageSlide.vue'
import GallerySlide from '@/components/slides/GallerySlide.vue'
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider'
import throttle from 'lodash/throttle'
import * as slides from '../store/modules/static-about.js'


export default {
  name: 'aboutUs',
  components: { ScrolltoNextslide, 
    'about-us.sponsor-slide-with-navigation': SponsorSlide, 
    'about-us.content-slide': ContentSlide, 
    'about-us.title' : TitleSlide, 
    'about-us.image-slide': ImageSlide,
    'about-us.gallery-slide': GallerySlide
  },
  data() {
    return {
      item: {},
      loaded: false,
      selectedIdx: 0,
      slides: []
    }
  },
  computed: {
    ...mapState('app', ['locale', 'config']),
  },
  watch: {
    locale() {
      this.reloadContent()
    }
  },
  methods: {
    async reloadContent() {
      this.loaded = false
      this.slides = slides[this.locale.toLowerCase()]
      this.loaded = true
    },
    _onScroll() {
      const scrollTop = this.$refs.scrollContainer.scrollTop
      let i = 0
      let selectedIdx = 0
      for (const child of this.$refs.scrollContainer.children) {
        if (child.offsetTop - 100 <= scrollTop) {
          selectedIdx = i
        }
        i ++;
      }
      this.selectedIdx = selectedIdx;
    },
    scrollTo(idx = 0) {
      const scrollToNode = this.$refs.scrollContainer.children[idx];
      this.$scrollTo(scrollToNode, 500, {
        container: this.$refs.scrollContainer,
        force: true,
        cancelable: false,
        x: false, y: true,
      })
    }
  },
  mounted() {
    this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
  },
  async created() {
    this.handleScroll = throttle(this._onScroll.bind(this), 100, {leading: true, trailing: true})
    await this.reloadContent()
  }
  
}
</script>

<style scoped>
.content-wrapper {
  width: 954px;
  overflow-y: hidden;
}

.content-slide {
  position: relative;
}
</style>
