<template>
  <div class="gallery-slide content-slide full-height" :class="slide.style">
    <div class="fullsize-container" v-if="fullsizeImg !== null" @click.stop="fullsizeImg = null">
      <div class="fullsize-image" :style="`background-image: url(${fullsizeImg.url})`"></div>
    </div>
    <div class="slider-wrapper">
      <div class="arrow-left" :class="{ active: slideIdx > 0}" @click.stop="prevSlide"></div>
      <div class="arrow-right" :class="{ active: slideIdx < groups.length-1 }" @click.stop="nextSlide"></div>
      <div class="slide" v-for="(group, idx) in groups" :key="`slide-group-${idx}`" v-show="idx === slideIdx">
        <div class="image" v-for="(item, idx) in group" :key="`group-img-${idx}`" @click.stop="fullsizeImg = item" 
          :style="`background-image: url(${item.formats.medium.url})`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

function * batchify(list = [], batchsize = 2) {
  const listClone = list.map( i => i )
  while (listClone.length > 0) {
    yield listClone.splice(0, batchsize)
  }
}


export default {
  props: {
    slide: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      slideIdx: 0,
      fullsizeImg: null
    }
  },
  computed: {
    ...mapState('app', ['config']),

    imagesPerSlide() {
      return this.slide.style == 'grid_6' ? 6 : 4
    },
    groups() {
      const groups = []
      for(const batch of batchify(this.slide.images, this.imagesPerSlide)) {
        groups.push(batch)
      }
      return groups
    }
  }, 
  methods: {
    prevSlide() {
      if (this.slideIdx === 0) {
        return 
      }

      this.slideIdx = this.slideIdx - 1
    },
    nextSlide() {
      if (this.slideIdx === this.groups.length - 1) {
        return 
      }

      this.slideIdx = this.slideIdx + 1
    }
  }
}
</script>

<style scoped>
.gallery-slide .slider-wrapper {
  position: relative;
  height: 600px;
  display: flex;
  align-items: center;
}
.gallery-slide .slider-wrapper .slide {
  display: flex;
  padding: 30px 80px;
  flex-wrap: wrap;
}
.gallery-slide .slider-wrapper .image {
  width: calc((954px - 160px) / 2 - 20px);
  margin: 10px;
  height: 250px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid white;
}

.gallery-slide.grid_6 .slider-wrapper .image {
  width: calc((954px - 160px) / 3 - 30px);
  height: 200px;
  margin: 20px 15px;
}

.fullsize-container {
  position: fixed;
  z-index: 30;
  width: 954px;
  height: 600px;
  overflow: hidden;
  top: 0;
  left: 0;
}
.fullsize-container .fullsize-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.arrow-left, .arrow-right {
  position: absolute;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #333;
  width: 40px;
  height: 40px;
  z-index: 10;
  top: 50%;
  left: 40px;
  transform: translate(0, -50%) rotate(135deg);
}
.arrow-left.active,
.arrow-right.active {
  border-color: #ccc;
}
.arrow-right {
  right: 40px;
  left: auto;
  transform: translate(0, -50%) rotate(-45deg);
}
</style>