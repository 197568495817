<template>
  <div class="device-config-wrapper">
      <div class="unit-info">
        <label>IP</label>
        <div>{{ unit.ip }}</div>
        <label>ID</label>
        <div>{{ unit.ip }}</div>
        <label>Anzeige</label>
        <div v-html="unit.displayName"></div>
      </div>
  </div>
</template>


<script>
import {mapState} from 'vuex'
export default {
  computed:  {
    ...mapState('app', ['locale', 'unit', 'config']),
  }
}
</script>


<style scoped>
.device-config-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 1024px;
  height: 600px;
  z-index: 3000;
  background: rgba(0,0,0,0.7);
}

.unit-info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  transform: translate(-50%, -50%);
  font-size: 52px;
  text-align: center;
}
.unit-info label {
  display: blocK;
  font-size: 34px;
}
</style>