<template>
  <div class="main-nav noselect">
    <div class="clickable main-nav__item" @click="$router.push('/start')">
      <img class="noselect" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqBAMAAAA37dRoAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAhUExURQEBAQAAAH5+foKCggYGBnZ2domJiWhoaIGBgRkZGRAQEKrqT8MAAACGSURBVCjP1ZOxCYAwFETvgwP8g0zgCoK1ghvYuIDiADZO4gTuaaFV/qWw0+vyEgK5vA+qwEmPlESABjd1gzhKgt4FOMAorkCiE1l6nHM71Vk26Fd8mMZ26TCF4QL3OJZ1bLLsSIxVGpzVi3+LGL/rV1uijdL2aVO11YUm9bQUJuvZv024FxdAsUHV1uojlQAAAABJRU5ErkJggg==" alt="" />
    </div>
    <div class="clickable main-nav__item" @click.stop="toggleLocale">
      <div class="en-icon" v-if="locale === 'de'"></div>
      <div class="de-icon" v-if="locale === 'en'"></div>
    </div>
    <div class="clickable" @click="$router.push('/help')">
      <div class="help-icon"></div>
    </div>
    <div class="dots">
      <slot>
        <div class="dots__dotwrapper">
            <div class="dot" v-for="(dot, group, idx) in renderedDots"
              :class="{ active: dot.indexOf(navInfo.index) !== -1 }"
              :key="idx"
              @click.stop="$emit('navigation', dot[0])"
            ></div>
        </div>
      </slot>
    </div>
    <div class="timer" v-if="timeRemaining > 0" v-html="timeRemainingFormatted"></div>
    <div class="clickable main-nav__item" @click.stop="$store.commit('app/TURN_ON_OFF', false)">
      <img class="noselect" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAtCAAAAAD9raY/AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAGRSURBVBgZjcChautgGADQwwc/RERURVwZNzNTU1UxSKEQUZEH2DvUTNUFAoWJMBO7N9gbXtp7lzbd1uW4CAIoSnMt12aKzc49ycVm5yLcs96aKdYb4Y7CqKqNFggTaZULwKY22i5cSxR9nQBUtdF2+GOUBQ/DMjOqaqO8+ChIIEc5LCEAVe0iio88ZCB4XbtW1S7CtpdAcFgngICqdhHUewGUfQhXqtookO3LAPrMVFW7ErLiDTJlF6aq2lS8lOA9ubFrTITsHfI23HjamgjRLvDUuFVt3Wo22Jcmgt3OrfIFr+HWauWLHr0vsswXb+jM0uFghnDAwQzhgM4sHXqzvOE1zNFjX5qhfMFTY4Zmg7wNvwjRLuA9+UXI3sFjS3JHEvsH4Fgkd6WiB+FhIPwkCcMjEA67AAhfpee9Udv4UagHCcCibyB8IzV94cpyWGa+tfjzURIuir5OvtMMQZh4GZYkU4/tc064UR66BxPloVvlQLiSWB6PzwDy3fC69IMgq47Hrmu7ruvbFWGeSBAA/gK7QUe+69uB5wAAAABJRU5ErkJggg==" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  components: { },
  props: {
    backRoute: {
      type: String,
      required: false,
    },
    navInfo: {
      type: Object,
      required: false,
      default: () => ({
        pages: 0,
        index: 0,
        slideItems: [],
      }),
    },
  },
  data() {
    return {
      vb: '0 0 42 42',
      h: 42,
      w: 42,
    }
  },
  computed: {
    ...mapState('app', ['locale', 'timeRemaining']),
    timeRemainingFormatted() {
      const timeRemaining = parseInt(this.timeRemaining)
      if (timeRemaining < 0) return ''
      const minutes = parseInt(timeRemaining / 60)
      let seconds = timeRemaining - minutes * 60
      seconds = seconds < 10 ? `0${seconds}` : seconds
      return `${minutes}:${seconds}`
    },
    renderedDots() {
      const dots = {}
      for (var i = 0; i < this.navInfo.slideItems.length; i++) {
        const d = this.navInfo.slideItems[i]
        if (!Array.isArray(dots[d.dataset.navgroup])) {
          dots[d.dataset.navgroup] = []
        }
        dots[d.dataset.navgroup].push(i)
      }
      return dots
    },
  },
  methods: {
    toggleLocale() {
      const newLocale = this.$i18n.locale() === 'de' ? 'en' : 'de'
      moment.locale(newLocale)
      this.$store.commit('app/CHANGE_LOCALE', newLocale)
      this.$i18n.set(newLocale)
    },
  },
}
</script>

<style scoped>
.main-nav {
  background-color: black;
  display: block;
  height: 600px;
  padding: 18px 8px;
  width: 70px;
  color: #ffffff;
  border-left: 1px solid #ccc;
  padding-top: 13px;
}

.main-nav__item {
  height: 42px;
}
.main-nav__item:first-child,
.main-nav__item:nth-of-type(2) {
  margin-bottom: 12px;
}
.main-nav__item:last-of-type {
  position: absolute;
  bottom: 19px;
}

.en-icon,
.de-icon,
.help-icon {
  border: 1px solid #ccc;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  text-align: center;
}
.en-icon:before,
.de-icon:before,
.help-icon:before {
  font-family: 'GT Super Vienna Display';
  font-size: 18px;
  line-height: 38px;
  color: white;
}

.en-icon:before {
  content: 'EN';
}
.de-icon:before {
  content: 'DE';
}
.help-icon:before {
  content: '?';
}
.timer {
  height: 40px;
  width: 40px;
  border: 1px solid #ccc;
  color: white;
  background-color: rgba(166, 9, 0, 1);
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  position: absolute;
  bottom: 80px;
}

.dots {
  display: block;
  height: calc(600px - (4 * 44px) - (5 * 18px) - 28px);
  position: relative;
}
.dots .dot {
  display: block;
  width: 10px;
  height: 10px;
  margin: 6px auto 20px 16px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ccc;
  user-select: none;
}
.dots .dot.active {
  background-color:white;
  width: 14px;
  height: 14px;
  margin-bottom: 18px;
  margin-left: 14px;
}

.dots .dot.hidden {
  display: none;
}

.dots__dotwrapper {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}

.next {
  width: 0px;
  height: 0px;
  -webkit-transform: rotate(360deg);
  border-style: solid;
  border-width: 20px 0 20px 30px;
  border-color: transparent transparent transparent #808080;
}
</style>
