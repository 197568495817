import Vue from 'vue'
import Vuex from 'vuex'

import vuexI18n from 'vuex-i18n'
import { de, en, FALLBACK_LANG } from '@/locale'

import * as app from '@/store/modules/app.js'
import * as subs from '@/store/modules/subs.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    subs,
  },
  state: {},
  mutations: {},
  actions: {},
})

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('en', en)
Vue.i18n.add('de', de)
Vue.i18n.fallback(FALLBACK_LANG)

Vue.i18n.set('de')

export default store
