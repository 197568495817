<template>
  <div class="keyboard-wrapper" :class="{active}" @click.stop="() =>{}">
    <div :class="keyboardClass"></div>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

const LAYOUTS = {
  email: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    'q w e r t z u i o p',
    'a s d f g h j k l',
    '_ y x c v b n m @ .',
    '{enter}',
  ],
  text: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    'q w e r t z u i o p',
    'a s d f g h j k l',
    '_ y x c v b n m @ .',
    '{space} {enter}',
  ],
}

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      type: String,
    },
    layout: {
      type: String,
      default: 'email',
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: LAYOUTS[this.layout],
      },
      display: {
        '{bksp}': '⟵',
        '{space}': ' ',
        '{enter}': this.$t('done'),
      },
    })
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.keyboard-wrapper {
  padding: 0 48px!important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 100;
  width: 954px;
  bottom: 0;
  height: 0px;
  overflow: hidden;
  transition: height 0.25s ease-in-out;
}
.keyboard-wrapper.active {
  height: 292px;
}

.keyboard-wrapper .simple-keyboard .hg-button {
  background-image: linear-gradient(-90deg, #444 0, #333 100%);
  color: white;
  height: 44px;
  margin-bottom: 4px;
  font-size: 24px;
}

</style>
