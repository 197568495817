<template>
  <div class="preview-wrapper" :class="{
    transition
  }" style="background: black;" @click.stop="next()">
    <div class="next-arrow" :class="{
      dark: darkTheme
    }">&rarr;</div>

    <div class="overlay"></div>

    <component :active="slides[0] === slide" :slide="slide" :is="slide.type || slide.__component" v-for="(slide, idx) of slides" :key="slide.id + '' + idx" @next="next()" @skip="_next(true)"></component>
  </div>
</template>

<script>
import ProgramText from '@/components/preview/ProgramText.vue'
import IronCurtain from '@/components/preview/IronCurtain.vue'
import GeneralSponsors from '@/components/prescreens/GeneralSponsors.vue'
import ProductionSponsor from '@/components/preview/ProductionSponsor.vue'
import VideoPlayer from '@/components/preview/VideoPlayer.vue'
import TextSlide from '@/components/prescreens/TextSlide.vue'
import ImageSlide from '@/components/slides/ImageSlide.vue'
import SpecialWelcome from '@/components/slides/SpecialWelcome.vue'
import throttle from 'lodash/throttle'
import AnimatedProgram from '@/components/preview/AnimatedProgram.vue'

import { getPrescreens } from '../store/modules/static-prescreens.js'
import { mapState } from 'vuex'

export default {
  components: { 
    'prescreen.video': VideoPlayer, 
    'prescreen.text': TextSlide,
    GeneralSponsors, IronCurtain,
    ProductionSponsor,
    SpecialWelcome, AnimatedProgram,
    'prescreen.picture': ImageSlide },
  data() {
    return {
      slides: [],
      transition: false,
    }
  },
  computed: {
    ...mapState('app', ['config', 'locale', 'specialWelcome']),
    darkTheme() {
      if (this.slides.length === 0) return true
      return this.slides[0].theme === 'light'
    }
  },
  methods: {
    _next(force = false) {
      
      if (this.transition && !force) return;

      this.transition = true;
      clearTimeout(this.nextTimeout)
      if (this.slides.length === 1) {
        return this.$router.push({name: 'startmenu'}).catch(e => {})
      }

      setTimeout(() => {
        this.slides.shift()
      }, 500);
      
      setTimeout(() => {
        this.transition = false;
        this.scheduleNext()
      }, 1000 )
      
    },
    scheduleNext(seconds = 10) {

      if (!this.slides[0]) {
        clearTimeout(this.nextTimeout)
        return
      }

      clearTimeout(this.nextTimeout)
      seconds = this.slides[0].duration || 10
      this.nextTimeout = setTimeout(() => {
        this.next()
      }, seconds*1000)
    }
  },
  async created() {
    this.next = throttle(this._next.bind(this), 2000, { leading: true, trailing: false })
    
    const slides = []

    if (Array.isArray(slides)) {
      this.slides = [
        ...slides,
        { type: 'AnimatedProgram'},
        { type: 'IronCurtain' },
        { type: 'GeneralSponsors' },
        { type: 'ProductionSponsor' },
      ]
    } else {
      this.slides = []
    }

    if (this.specialWelcome && this.specialWelcome.status === true) {
      this.slides.unshift({
        type: 'SpecialWelcome',
        image: {
          static: true,
          url: '/images/uni-goes-opera-min.jpg',
        },
      })
    }
    this.scheduleNext()
  }
}
</script>

<style scoped>
.preview-wrapper {
  width: 1024px;
  height: 600px;
  position: relative;
  overflow-y: hidden;
}
.preview-wrapper * {
  user-select: none;
}
  
.preview-wrapper .overlay {
  background: black;
  opacity: 0;
  position: absolute;
  z-index: 20;
  top: 0;
  width: 1024px;
  height: 600px;
  transition: opacity 0.5s ease-in-out;
}

.preview-wrapper.transition .overlay {
  opacity: 1;
  background: black;
}

.preview-wrapper .next-arrow {
  border: 1px solid #ccc;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  position: fixed;
  z-index: 10;
  color: white;
  border-radius: 50%;
  left: 914px;
  top: 260px;
  font-size: 40px;
  font-family: 'GT Super Vienna Display';
}
.preview-wrapper .next-arrow.dark {
  border-color: black;
  color: black;
}

</style>

<style>

svg text {
  font-size: 50px;
  font-family: 'GT Super Vienna Display';
  stroke: transparent;
  stroke-width: 1.5px;
}
</style>