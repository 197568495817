<template>
  <div class="preview-wrapper" :class="{
    transition
  }" style="background: black;" @click.stop="next()">
    <div class="next-arrow" :class="{
      dark: darkTheme
    }">&rarr;</div>

    <div class="overlay"></div>

    <component :active="slides[0] === slide" :slide="slide" :is="slide.type || slide.__component" v-for="(slide, idx) of slides" :key="slide.id + '' + idx" @next="next()"></component>
  </div>
</template>

<script>
import ProgramText from '@/components/preview/ProgramText.vue'
import IronCurtain from '@/components/preview/IronCurtain.vue'
import GeneralSponsors from '@/components/prescreens/GeneralSponsors.vue'
import ProductionSponsor from '@/components/preview/ProductionSponsor.vue'
import VideoPlayer from '@/components/preview/VideoPlayer.vue'
import TextSlide from '@/components/prescreens/TextSlide.vue'
import ImageSlide from '@/components/slides/ImageSlide.vue'
import throttle from 'lodash/throttle'

import { mapState } from 'vuex'

export default {
  components: { 
    'prescreen.video': VideoPlayer, 
    'prescreen.text': TextSlide,
    GeneralSponsors, IronCurtain,
    ProductionSponsor,
    'prescreen.picture': ImageSlide },
  data() {
    return {
      slides: [],
      transition: false,
      intermissionFollowUp: false,
    }
  },
  computed: {
    ...mapState('app', ['config', 'locale']),
    darkTheme() {
      if (this.slides.length === 0) return true
      return this.slides[0].theme === 'light'
    }
  },
  methods: {
    _next() {
      if (this.transition) return;

      this.transition = true;
      clearTimeout(this.nextTimeout)
      if (this.slides.length === 1) {
        return this.leave()
      }

      setTimeout(() => {
        this.slides.shift()
      }, 500);
      
      setTimeout(() => {
        this.transition = false;
        this.scheduleNext()
      }, 1000 )
      
    },
    scheduleNext(seconds = 10) {
      clearTimeout(this.nextTimeout)
      seconds = this.slides[0].duration || null

      if (seconds !== null) {
        this.nextTimeout = setTimeout(() => {
          this.next()
        }, seconds*1000)
      } else {
        this.next()
      }
    },
    leave(){
      if (this.$route.name !== 'intermission') return


      if (this.intermissionFollowUp === true) {
        this.$router.push({name: 'intermissionfollowup'}).catch(e => {})
        return
      }

      this.$router.push({name: 'startmenu'}).catch(e => {})
    },
  },
  async created() {
    this.next = throttle(this._next.bind(this), 2000, { leading: true, trailing: false })
    let apiUrl = this.config.DATA_API + '/intermission?language=de'
    if (this.locale !== 'de') {
      apiUrl = this.config.DATA_API + '/intermission?language=en'
    }
    const { data:slides } = await this.$axios.get(apiUrl)

    if (Array.isArray(slides) && slides.length > 0) {
      this.slides = [
        ...slides,
      ]
    } else {
      this.slides = []
      return this.leave()
    }

    try {
      const { data } = await this.$axios.get(this.config.DATA_API + '/status/intermissionfollowup')
      this.intermissionFollowUp = data || false
    } catch (e) {

    }
    
    this.scheduleNext()
  }
}
</script>

<style scoped>
.preview-wrapper {
  width: 1024px;
  height: 600px;
  position: relative;
  overflow-y: hidden;
}
.preview-wrapper * {
  user-select: none;
}
  
.preview-wrapper .overlay {
  background: black;
  opacity: 0;
  position: absolute;
  z-index: 20;
  top: 0;
  width: 1024px;
  height: 600px;
  transition: opacity 0.5s ease-in-out;
}

.preview-wrapper.transition .overlay {
  opacity: 1;
  background: black;
}

.preview-wrapper .next-arrow {
  border: 1px solid #ccc;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  position: fixed;
  z-index: 10;
  color: white;
  border-radius: 50%;
  left: 914px;
  top: 260px;
  font-size: 40px;
  font-family: 'GT Super Vienna Display';
}
.preview-wrapper .next-arrow.dark {
  border-color: black;
  color: black;
}

</style>

<style>

svg text {
  font-size: 50px;
  font-family: 'GT Super Vienna Display';
  stroke: transparent;
  stroke-width: 1.5px;
}
</style>