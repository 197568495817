export default {
  it_news: 'News',
  it_goto_home: 'startmenu',
  it_contents: 'Content',
  it_row: 'Row',
  it_about: 'About us',
  it_faq: 'Questions & Answers',
  it_feedback: 'Feedback',
  it_newsletter: 'Newsletter',
  it_seat: 'Seat',
  it_more_info: 'More Information',
  it_insights: 'News',
  all_german: 'Deutsch',
  all_english: 'English',
  all_more: 'more',
  all_start: 'Start',
  all_break: 'Break approx.',
  all_end: 'End',
  all_next: 'next',
  all_back: 'back',
  it_uhr: ' ',
  it_faq_short: 'FAQ',
  it_marble_hall: 'Marble Hall (Marmorsaal)',
  production_today: 'TODAY',
  det_cast: 'CAST',
  det_leadingteam: 'LEADING TEAM',
  det_sponsor: 'This production is supported by',
  det_summary: 'SUMMARY',
  det_more: 'more in the Program Guide',
  det_dl: 'or via download',
  det_dates: 'UPCOMING DATES',
  available_as_stream:
    'This performance may also be viewed <strong>live via www.staatsoperlive.com</strong>',
  det_email: 'Your email adress',
  det_gensp: 'GENERAL SPONSORS OF THE WIENER STAATSOPER',
  feedback_title: 'Feedback',
  feedback_intro:
    'Please help us to improve our offers by answering some questions.',
  feedback_question: 'Question',
  feedback_of: 'of',
  faq_headline: 'Fequently asked Questions',
  gastro_introtext:
    "<span class='boldFont'>Pre-order here and enjoy your reservation completely relaxed without having to queue at the buffet!</span><br>Your culinary intermission experience can be conveniently arranged here with just a few clicks . The Gerstner team looks forward to welcoming you and will be delighted to fulfill your culinary desires. Your pre-order will be ready for pickup and payment in the Marble Hall.",
  gastro_price: 'Price',
  gastro_amount: 'Amount',
  gastro_title: 'Gastronomy',
  gastro_widgettitle: 'Order for the next break',
  gastro_strong1: 'On our menu ',
  gastro_text1:
    'we have prepared popular offers, that you are welcome to order comfortably, with just a few clicks, from your seat.',
  gastro_strong2: 'Calmly enjoy ',
  gastro_text2:
    'your refreshments, without standing in line, at a reserved table or at the bar. The Gersner Catering Team is looking forward to your order.',
  gastro_offerbtn: 'see offers',
  gastro_loadappstrong: 'Download the „Order-App“ from Gerstner,',
  gastro_loadappnormal: 'so you can place your order already early on.',
  gastro_recommandations: 'Our Recommandations',
  gastro_food: 'DISHES',
  gastro_beverages: 'BEVERAGES',
  gastro_packages: 'Packages',
  gastro_order: 'order now',
  gastro_yourorder: 'Your order',
  gastro_total: 'Total',
  gastro_back: 'back',
  gastro_placeorder: 'finalize order',
  gastro_thanks: 'Thank you for your order',
  gastro_ordersum: 'Total of your order ',
  gastro_confirmation:
    'Please enter the name, under which you will pick up your pre-order at the &bdquo;Gerstner Intermission Service&quot;.',
  gastro_delete: 'Delete',
  gastro_confirm: 'Confirm',
  gastro_sendorder: 'send order',
  gastro_cancel: 'cancel',
  gastro_confirmheadline: 'Please confirm your order',
  gastro_orderpickupinfo:
    'Your order will be prepared and will be waiting for you during the next intermission at the &bdquo;Gerstner Intermission Service&quot; in the Marble Hall.',
  gastro_alergen:
    'Our staff at our buffets will be pleased to inform you about allergenes & additives in our products.',
  gastro_reservationname: 'Reservationname',
  gastro_locationplan: 'pick-up point',
  gastro_name_label: 'Your Name',
  gastro_sending: 'Placing your order ...',
  news_insights: 'News',
  newsletter_header: 'Newsletter',
  newsletter_intro: "Bleiben Sie auf dem Laufenden mit unseren Newslettern zu verschiedenen Themen und erfahren Sie alle Neuigkeiten aus dem Haus am Ring.",
  newsletter_description:
    'Yes, I would like to receive information about the following:',
  newsletter_news: 'NEWSLETTER GENERAL TOPICS',
  newsletter_jugend: 'U27 / YOUNG AUDIENCE',
  newsletter_familyfriends: 'family & friends',
  newsletter_ballett: 'WIENER STAATSBALLETT',
  newsletter_label: 'PLEASE ENTER YOUR EMAIL ADDRESS HERE',
  newsletter_placeholder: 'Enter e-mail address',
  newsletter_btn_back: 'back',
  newsletter_btn_subscribe: 'subscribe',
  newsletter_wrong_email: 'Please enter a valid email address',
  newsletter_subscriber_exists: 'This email address is already registered',
  newsletter_success:
    'Thank you for registering!<br><br>You will shortly receive an email with a link to complete your registration.<br><br> Your Team of Oper',
  read_more_in_programm: 'More in our programme books',
  read_more_in_where:
    'obtainable from the ushers, or in the digital version for download in our Oper Publications App.',
  visitor_greeting: 'Dear visitor',
  visitor_confirm: 'Confirm',
  change_language: 'change language',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  it: 'Italiano',
  fr: 'Français',
  ru: 'Pусский',
  ja: '日本語',
  zh: '中文',
  ov: 'Original version',
  select_language_headline: 'Please select your preferred subtitle language',
  warning: {
    headline: 'No pictures or audio recordings!',
    salut: 'Dear visitor,',
    switch: 'subtitles will be shown',
    turnoff: 'infotainment will be deactivated',
    desc: 'It is not allowed to take pictures or make video or audio recordings of the performance!',
    thanks: 'Please turn of your mobile phones.<br>We wish you a pleasant evening.'
  },
  x_minutes: ['in {count} minute', 'in {count} minutes'],
  x_seconds: ['in {count} second', 'in {count} seconds'],
  close: 'close',
  help: 'Help',
  help_description: `
  <p>Welcome to our subtitling and information system.</p>
  <br>
  <p>
    We will automatically switch to display the subtitles shortly before
    the start of the performance. <br>Please select your preferred language then,<br>
    or switch off the display.<br>
  </p>
  `,
  done: 'Done',
  nav: {
    main: 'Back to Mainmenu',
    about: 'About Oper',
    production: 'About todays Performance',
    next: {
      production: 'About todays Performance',
      about: 'About Oper',
    },
    back: {
      startmenu: 'Back to Mainmenu',
      production: 'About todays Performance',
    }
  },
  ironCurtainInfo: 'The Iron Curtain of the<br>2020-21 season is created<br>by Carrie Mae Weems',
  supportedBy: 'With kind support of the <br>Christian Zeller Foundation',
  general_sponsors_thanks: 'Oper would like to<br>thank its general sponsors!',
  survey: {
    headline: 'Your opinion is important to us!',
    intro: 'Please answer {count} short questions.',
    cancel: 'Cancel Survey',
    start: 'Start',
    outro_headline: 'Thank you for your participation',
    outro_text: '',
    back: 'Back to main menu',
  },
  blocknotification: {
    default: 'Do not lean over the parapet!',
  },
  prescreen:  {
    production: 'The production',
    supported_by: 'is supported by'
  },
  newsletter_privacy: `
  Your data will be used solely for the transmission of your selected newsletters by the Oper (and its official data processors) and will not be passed on to third parties.<br><br>
  Your consent to receive our newsletter can be revoked at any time via the unsubscribe link in every newsletter.
  `,
  intermissionFollowup: {
    text: "Florence at carnival, not so long ago.<br><br>Buoso Donati at dinner.<br><br>Radio Mussoloni plays fascist songs and speeches from five decades."
  }
}
