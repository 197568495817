<template>
  <div class="sponsors-wrapper" :class="{ active }" :style="`background-image: url(${production.headerImage});background-size:cover;`">
    <div class="headline-top"></div>
    <div class="text-container" v-if="sponsor !== ''">
      <div class="headline-top">{{ 'prescreen.production' | translate }}</div>
      <div class="production-title" v-html="production.title"></div>
      <div class="headline-sub">{{'prescreen.supported_by' | translate}}</div>
      <div class="sponsor">
        <img src="/img/philoro_sw.png" v-if="sponsor == 'philoro'" height=210 />
        <img src="/img/sponsor_gerstner_white_2023.png" v-if="sponsor == 'gerstner'" height=210 />
        <img src="/img/sponsor_muenze_at.png" v-if="sponsor == 'muenze_at'" height=210 />
        <img src="/img/sponsor-rbi-combined.png" v-if="sponsor == 'rbi'" height=120 />
        <!-- <img src="/img/sponsor_rbi_white_2023.png" v-if="sponsor == 'rbi'" height=120 /> -->
        <img src="/img/sponsor_agrana.jpg" v-if="sponsor == 'agrana'" height=170 />
        <img src="/img/logo-mastercard-new.png" v-if="sponsor == 'mastercard'" height=210 />
        <img src="/img/sponsor_magna.png" v-if="sponsor == 'magna'" height=150 />
        <img src="/img/sponsor_lexus_2024.png" v-if="sponsor == 'lexus'" width="500"  />
        <img src="/img/sponsor_meinl.png" v-if="sponsor == 'meinl'" height=210 />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'


const SponsorMap = {
  'gerstner': [
    'trittico',
    'meistersinger',
    'tristan',
    'otello',
    'faust',
  ],
  'lexus': [
    'cenerentola',
    'manon',
    'falstaff',
  ],
  'rbi': [
    'lohengrin',
    'nozze',
    'giovanni',
    'pasquale',
    'rusalka',
  ],
  'mastercard': [
    'salome',
  ],
  'philoro':  [
    'serail',
  ]
}

export default {
  props: {
    active:{
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('app', ['locale', 'config', 'production']),
    sponsor(){

      return 'mastercard'
      if (!this.production || !this.production.title) return ''

      try {
        const title = this.production.title.toLowerCase().trim()

        for (const entry of Object.entries(SponsorMap)) {
          const [sponsor, titles] = entry
          for (const titlePart of titles) {
            if (title.indexOf(titlePart) !== -1) {
              return sponsor
            }
          }
        }
      } catch (e) {}
      
      return ''
    }
  },
  mounted(){
    if (this.sponsor === '' && this.active) {
      this.$emit('skip')
    }
  }
  
}
</script>

<style scoped>
.sponsors-wrapper {
  width: 1024px;
  height: 600px;
  color: white;
  position: relative;
}
.sponsors-wrapper:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: 1;
}

.text-container {
  width: 1024px;
  padding: 20px 20px;
  height: 600px;
  position: absolute;
  top: 0; left: 0;
  z-index: 2;
  text-align: center;
}
.headline-top {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 52px;
  line-height: 60px;
  text-overflow: ellipsis;
  z-index: 2;
}

.production-title {
  font-family: 'GT Super Vienna Display';
  text-transform: uppercase;
  font-size: 60px;
  line-height: 55px;
  text-overflow: ellipsis;
  margin-top: 15px;
  z-index: 2;
}

.headline-sub {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 48px;
  line-height: 55px;
  text-overflow: ellipsis;
  margin-top: 15px;
  z-index: 2;
}

.sponsor {
  position: absolute; 
  bottom: 75px;
  font-size: 22px;
  align-items: center;
  font-family: 'GT Super Vienna Display';
  left: 50%;
  transform: translateX(-50%);
  /* background: rgba(255,255,255,0.1); */
}
</style>