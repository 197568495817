export function splitContent(content, charsPerPage = 500) {
  if (!content) return []

  content = content.replace(/<p>/g, '')
  content = content.replace(/<\/p>/g, '\n')

  const strs = []
  const l = charsPerPage

  while (content.length > l) {
    let pos = content.substring(0, l).lastIndexOf(' ')

    pos = pos <= 0 ? l : pos
    strs.push(`${content.substring(0, pos)} `)
    let i = content.indexOf(' ', pos) + 1

    if (i < pos || i > pos + l) i = pos
    content = content.substring(i)
  }
  strs.push(content)

  return strs || []
}
