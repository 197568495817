export const de = [
  {
    "__component": "about-us.content-slide",
    "id": 3,
    "slideTitle": "Über Uns",
    "text": "Als eines der international bedeutendsten Opernhäuser blickt die Wiener Staatsoper sowohl auf eine traditionsreiche Vergangenheit zurück als auch auf eine vielseitige Gegenwart:\nJede Spielzeit stehen in rund 350 Vorstellungen mehr als 60 verschiedene Opern- und Ballettwerke auf dem Spielplan. Allabendlich sind neben den fest engagierten Ensemblemitgliedern internationale Stars auf der Bühne und am Dirigentenpult zu erleben, im Graben begleitet von einem einzigartigen Orchester: dem Staatsopernorchester, dessen Musiker*innen in Personalunion die Wiener Philharmoniker bilden."
  },
  {
    "__component": "about-us.image-slide",
    "id": 4,
    "image": {
      "id": 113,
      "name": "Social-Media_Screens-NEU_Stand 2023 10.png",
      "alternativeText": "",
      "caption": "",
      "width": 4258,
      "height": 2500,
      "formats": {
        "thumbnail": {
          "name": "thumbnail_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "thumbnail_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 245,
          "height": 144,
          "size": 15.38,
          "path": null,
          "url": "/uploads/thumbnail_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        },
        "large": {
          "name": "large_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "large_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 1000,
          "height": 587,
          "size": 117.58,
          "path": null,
          "url": "/uploads/large_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        },
        "medium": {
          "name": "medium_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "medium_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 750,
          "height": 440,
          "size": 80.18,
          "path": null,
          "url": "/uploads/medium_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        },
        "small": {
          "name": "small_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "small_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 500,
          "height": 294,
          "size": 44.68,
          "path": null,
          "url": "/uploads/small_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        }
      },
      "hash": "Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
      "ext": ".png",
      "mime": "image/png",
      "size": 226.31,
      "url": "/uploads/Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png",
      "previewUrl": null,
      "provider": "local",
      "provider_metadata": null,
      "created_at": "2023-10-16T10:30:38.180Z",
      "updated_at": "2023-10-16T10:30:38.190Z"
    }
  },
  {
    "__component": "about-us.gallery-slide",
    "id": 1,
    "style": "grid_4",
    "images": [
      {
        "id": 21,
        "name": "2-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_2-(klein).jpg",
            "hash": "thumbnail_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 8.84,
            "path": null,
            "url": "/uploads/thumbnail_2_klein_c3b1c19be8.jpg"
          },
          "large": {
            "name": "large_2-(klein).jpg",
            "hash": "large_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 124.98,
            "path": null,
            "url": "/uploads/large_2_klein_c3b1c19be8.jpg"
          },
          "medium": {
            "name": "medium_2-(klein).jpg",
            "hash": "medium_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 74.5,
            "path": null,
            "url": "/uploads/medium_2_klein_c3b1c19be8.jpg"
          },
          "small": {
            "name": "small_2-(klein).jpg",
            "hash": "small_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 33.94,
            "path": null,
            "url": "/uploads/small_2_klein_c3b1c19be8.jpg"
          }
        },
        "hash": "2_klein_c3b1c19be8",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 134.29,
        "url": "/uploads/2_klein_c3b1c19be8.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.637Z",
        "updated_at": "2020-10-14T09:00:30.667Z"
      },
      {
        "id": 20,
        "name": "4-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_4-(klein).jpg",
            "hash": "thumbnail_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 9.05,
            "path": null,
            "url": "/uploads/thumbnail_4_klein_38df431779.jpg"
          },
          "large": {
            "name": "large_4-(klein).jpg",
            "hash": "large_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 116.05,
            "path": null,
            "url": "/uploads/large_4_klein_38df431779.jpg"
          },
          "medium": {
            "name": "medium_4-(klein).jpg",
            "hash": "medium_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 69,
            "path": null,
            "url": "/uploads/medium_4_klein_38df431779.jpg"
          },
          "small": {
            "name": "small_4-(klein).jpg",
            "hash": "small_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 32.39,
            "path": null,
            "url": "/uploads/small_4_klein_38df431779.jpg"
          }
        },
        "hash": "4_klein_38df431779",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 124.39,
        "url": "/uploads/4_klein_38df431779.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.590Z",
        "updated_at": "2020-10-14T09:00:30.605Z"
      },
      {
        "id": 19,
        "name": "7-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_7-(klein).jpg",
            "hash": "thumbnail_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 10.19,
            "path": null,
            "url": "/uploads/thumbnail_7_klein_39e8828105.jpg"
          },
          "large": {
            "name": "large_7-(klein).jpg",
            "hash": "large_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 149.7,
            "path": null,
            "url": "/uploads/large_7_klein_39e8828105.jpg"
          },
          "medium": {
            "name": "medium_7-(klein).jpg",
            "hash": "medium_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 88.26,
            "path": null,
            "url": "/uploads/medium_7_klein_39e8828105.jpg"
          },
          "small": {
            "name": "small_7-(klein).jpg",
            "hash": "small_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 39.2,
            "path": null,
            "url": "/uploads/small_7_klein_39e8828105.jpg"
          }
        },
        "hash": "7_klein_39e8828105",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 161.24,
        "url": "/uploads/7_klein_39e8828105.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.221Z",
        "updated_at": "2020-10-14T09:00:30.297Z"
      },
      {
        "id": 18,
        "name": "8-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_8-(klein).jpg",
            "hash": "thumbnail_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 8.12,
            "path": null,
            "url": "/uploads/thumbnail_8_klein_c9f10fdf0f.jpg"
          },
          "large": {
            "name": "large_8-(klein).jpg",
            "hash": "large_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 105.92,
            "path": null,
            "url": "/uploads/large_8_klein_c9f10fdf0f.jpg"
          },
          "medium": {
            "name": "medium_8-(klein).jpg",
            "hash": "medium_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 64.05,
            "path": null,
            "url": "/uploads/medium_8_klein_c9f10fdf0f.jpg"
          },
          "small": {
            "name": "small_8-(klein).jpg",
            "hash": "small_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 29.64,
            "path": null,
            "url": "/uploads/small_8_klein_c9f10fdf0f.jpg"
          }
        },
        "hash": "8_klein_c9f10fdf0f",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 114.66,
        "url": "/uploads/8_klein_c9f10fdf0f.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.079Z",
        "updated_at": "2020-10-14T09:00:30.162Z"
      },
      {
        "id": 17,
        "name": "6-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_6-(klein).jpg",
            "hash": "thumbnail_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 11.88,
            "path": null,
            "url": "/uploads/thumbnail_6_klein_58e3e2670c.jpg"
          },
          "large": {
            "name": "large_6-(klein).jpg",
            "hash": "large_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 185.05,
            "path": null,
            "url": "/uploads/large_6_klein_58e3e2670c.jpg"
          },
          "medium": {
            "name": "medium_6-(klein).jpg",
            "hash": "medium_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 108.18,
            "path": null,
            "url": "/uploads/medium_6_klein_58e3e2670c.jpg"
          },
          "small": {
            "name": "small_6-(klein).jpg",
            "hash": "small_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 47.87,
            "path": null,
            "url": "/uploads/small_6_klein_58e3e2670c.jpg"
          }
        },
        "hash": "6_klein_58e3e2670c",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 198.98,
        "url": "/uploads/6_klein_58e3e2670c.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.011Z",
        "updated_at": "2020-10-14T09:00:30.045Z"
      },
      {
        "id": 16,
        "name": "1-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_1-(klein).jpg",
            "hash": "thumbnail_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 7.63,
            "path": null,
            "url": "/uploads/thumbnail_1_klein_7598d5225b.jpg"
          },
          "large": {
            "name": "large_1-(klein).jpg",
            "hash": "large_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 96.9,
            "path": null,
            "url": "/uploads/large_1_klein_7598d5225b.jpg"
          },
          "medium": {
            "name": "medium_1-(klein).jpg",
            "hash": "medium_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 58.14,
            "path": null,
            "url": "/uploads/medium_1_klein_7598d5225b.jpg"
          },
          "small": {
            "name": "small_1-(klein).jpg",
            "hash": "small_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 27.5,
            "path": null,
            "url": "/uploads/small_1_klein_7598d5225b.jpg"
          }
        },
        "hash": "1_klein_7598d5225b",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 105.11,
        "url": "/uploads/1_klein_7598d5225b.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:29.886Z",
        "updated_at": "2020-10-14T09:00:29.918Z"
      },
      {
        "id": 15,
        "name": "5-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_5-(klein).jpg",
            "hash": "thumbnail_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 9.36,
            "path": null,
            "url": "/uploads/thumbnail_5_klein_544aec77de.jpg"
          },
          "large": {
            "name": "large_5-(klein).jpg",
            "hash": "large_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 130.42,
            "path": null,
            "url": "/uploads/large_5_klein_544aec77de.jpg"
          },
          "medium": {
            "name": "medium_5-(klein).jpg",
            "hash": "medium_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 77.94,
            "path": null,
            "url": "/uploads/medium_5_klein_544aec77de.jpg"
          },
          "small": {
            "name": "small_5-(klein).jpg",
            "hash": "small_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 35.49,
            "path": null,
            "url": "/uploads/small_5_klein_544aec77de.jpg"
          }
        },
        "hash": "5_klein_544aec77de",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 139.03,
        "url": "/uploads/5_klein_544aec77de.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:29.708Z",
        "updated_at": "2020-10-14T09:00:29.791Z"
      },
      {
        "id": 14,
        "name": "3-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_3-(klein).jpg",
            "hash": "thumbnail_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 11.45,
            "path": null,
            "url": "/uploads/thumbnail_3_klein_d7dc4aa2fe.jpg"
          },
          "large": {
            "name": "large_3-(klein).jpg",
            "hash": "large_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 169.78,
            "path": null,
            "url": "/uploads/large_3_klein_d7dc4aa2fe.jpg"
          },
          "medium": {
            "name": "medium_3-(klein).jpg",
            "hash": "medium_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 101.06,
            "path": null,
            "url": "/uploads/medium_3_klein_d7dc4aa2fe.jpg"
          },
          "small": {
            "name": "small_3-(klein).jpg",
            "hash": "small_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 44.95,
            "path": null,
            "url": "/uploads/small_3_klein_d7dc4aa2fe.jpg"
          }
        },
        "hash": "3_klein_d7dc4aa2fe",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 181.39,
        "url": "/uploads/3_klein_d7dc4aa2fe.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:29.526Z",
        "updated_at": "2020-10-14T09:00:29.603Z"
      }
    ]
  },
  {
    "__component": "about-us.content-slide",
    "id": 5,
    "slideTitle": "Der Offizielle Freundeskreis der Wiener Staatsoper",
    "text": "Im Offiziellen Freundeskreis der Wiener Staatsoper engagieren sich gleichgesinnte Opernliebhaber*innen, die am kulturellen Geschehen des Hauses aktiv teilhaben wollen. Sieben Levels der Mitgliedschaft bieten attraktive Vorteile, wie die bevorzugte Bestellung von Kaufkarten, regelmäßige Einladungen zu exklusiven Freundeskreis-Veranstaltungen, Einblicke hinter die Kulissen, sowie die aktive Teilnahme am künstlerischen Geschehen der Wiener Staatsoper. Als Mitglied unterstützen sie die Vermittlungs- und Fortbildungsprogramme, die sich an junge Menschen richten, sowie das Opernstudio mit seinem exzellenten Sängernachwuchs  und die Opernschule. Die Wiener Staatsoper zu unterstützen bedeutet nicht nur, einer der wichtigsten Kulturinstitutionen des Landes persönlich verbunden zu sein, sondern bietet auch die Befriedigung, Teil eines künstlerischen Dialogs und eines wichtigen gesellschaftlichen Auftrags zu werden.\n\nSchreiben Sie dem Offiziellen Freundeskreis unter freundeskreis@wiener-staatsoper.at\n\nWir freuen uns auf Sie!\n"
  },
  {
    "__component": "about-us.content-slide",
    "id": 11,
    "slideTitle": "Freundeskreis Wiener Staatsballett",
    "text": "Das Wiener Staatsballett zählt zu den bedeutendsten Tanzcompagnien der Welt. Der Freundeskreis Wiener Staatsballett hilft dabei, diesen Erfolg in die Zukunft zu tragen. Werden auch Sie ein Teil davon und unterstützen Sie Direktor und Chefchoreographen Martin Schläpfer in seiner Vision für die Zukunft der Compagnie. Als Dank für erhalten Sie nicht nur das Angebot bevorzugter Kartenbestellungen, wir haben auch ein vielfältiges und exklusives Programm für Sie vorbereitet: Künstler*innengespräche, Besuche von Bühnenproben und Trainings, aber vor allem auch Raum für interessante persönliche Begegnungen mit der Direktion sowie den Tänzerinnen und Tänzern des Wiener Staatsballetts und vieles mehr.\nWerden auch Sie Freund*in des Wiener Staatsballetts und helfen Sie uns dabei, auch in Zukunft das zu tun, was wir am besten können: Momente zu schaffen, die im wahrsten Sinne des Wortes bewegen! \nBei Fragen stehen wir Ihnen gern unter freundeskreis@wiener-staatsballett.at bereit.\n"
  },
  {
    "__component": "about-us.sponsor-slide-with-navigation",
    "id": 1,
    "backLink": "Startmenu",
    "nextLink": "Production"
  }
]

export const en = [
  {
    "__component": "about-us.content-slide",
    "id": 4,
    "slideTitle": "About us",
    "text": "Wiener Staatsoper is one of the leading opera houses in the world. Its past is steeped in tradition. Its present is alive with richly varied performances and events. Each season, the schedule features 350 performances of more than 60 different operas and ballets. can witness world-class artists alongside the permanent ensemble ensemble members on the stage and at the conductor’s desk, accompanied by a unique orchestra: the orchestra of Wiener Staatsoper, whose members at the same time make up the Vienna Philharmonic Orchestra."
  },
  {
    "__component": "about-us.image-slide",
    "id": 5,
    "image": {
      "id": 113,
      "name": "Social-Media_Screens-NEU_Stand 2023 10.png",
      "alternativeText": "",
      "caption": "",
      "width": 4258,
      "height": 2500,
      "formats": {
        "thumbnail": {
          "name": "thumbnail_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "thumbnail_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 245,
          "height": 144,
          "size": 15.38,
          "path": null,
          "url": "/uploads/thumbnail_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        },
        "large": {
          "name": "large_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "large_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 1000,
          "height": 587,
          "size": 117.58,
          "path": null,
          "url": "/uploads/large_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        },
        "medium": {
          "name": "medium_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "medium_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 750,
          "height": 440,
          "size": 80.18,
          "path": null,
          "url": "/uploads/medium_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        },
        "small": {
          "name": "small_Social-Media_Screens-NEU_Stand 2023 10.png",
          "hash": "small_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
          "ext": ".png",
          "mime": "image/png",
          "width": 500,
          "height": 294,
          "size": 44.68,
          "path": null,
          "url": "/uploads/small_Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png"
        }
      },
      "hash": "Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4",
      "ext": ".png",
      "mime": "image/png",
      "size": 226.31,
      "url": "/uploads/Social_Media_Screens_NEU_Stand_2023_10_bfd3d194a4.png",
      "previewUrl": null,
      "provider": "local",
      "provider_metadata": null,
      "created_at": "2023-10-16T10:30:38.180Z",
      "updated_at": "2023-10-16T10:30:38.190Z"
    }
  },
  {
    "__component": "about-us.gallery-slide",
    "id": 2,
    "style": "grid_4",
    "images": [
      {
        "id": 21,
        "name": "2-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_2-(klein).jpg",
            "hash": "thumbnail_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 8.84,
            "path": null,
            "url": "/uploads/thumbnail_2_klein_c3b1c19be8.jpg"
          },
          "large": {
            "name": "large_2-(klein).jpg",
            "hash": "large_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 124.98,
            "path": null,
            "url": "/uploads/large_2_klein_c3b1c19be8.jpg"
          },
          "medium": {
            "name": "medium_2-(klein).jpg",
            "hash": "medium_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 74.5,
            "path": null,
            "url": "/uploads/medium_2_klein_c3b1c19be8.jpg"
          },
          "small": {
            "name": "small_2-(klein).jpg",
            "hash": "small_2_klein_c3b1c19be8",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 33.94,
            "path": null,
            "url": "/uploads/small_2_klein_c3b1c19be8.jpg"
          }
        },
        "hash": "2_klein_c3b1c19be8",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 134.29,
        "url": "/uploads/2_klein_c3b1c19be8.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.637Z",
        "updated_at": "2020-10-14T09:00:30.667Z"
      },
      {
        "id": 20,
        "name": "4-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_4-(klein).jpg",
            "hash": "thumbnail_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 9.05,
            "path": null,
            "url": "/uploads/thumbnail_4_klein_38df431779.jpg"
          },
          "large": {
            "name": "large_4-(klein).jpg",
            "hash": "large_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 116.05,
            "path": null,
            "url": "/uploads/large_4_klein_38df431779.jpg"
          },
          "medium": {
            "name": "medium_4-(klein).jpg",
            "hash": "medium_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 69,
            "path": null,
            "url": "/uploads/medium_4_klein_38df431779.jpg"
          },
          "small": {
            "name": "small_4-(klein).jpg",
            "hash": "small_4_klein_38df431779",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 32.39,
            "path": null,
            "url": "/uploads/small_4_klein_38df431779.jpg"
          }
        },
        "hash": "4_klein_38df431779",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 124.39,
        "url": "/uploads/4_klein_38df431779.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.590Z",
        "updated_at": "2020-10-14T09:00:30.605Z"
      },
      {
        "id": 19,
        "name": "7-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_7-(klein).jpg",
            "hash": "thumbnail_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 10.19,
            "path": null,
            "url": "/uploads/thumbnail_7_klein_39e8828105.jpg"
          },
          "large": {
            "name": "large_7-(klein).jpg",
            "hash": "large_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 149.7,
            "path": null,
            "url": "/uploads/large_7_klein_39e8828105.jpg"
          },
          "medium": {
            "name": "medium_7-(klein).jpg",
            "hash": "medium_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 88.26,
            "path": null,
            "url": "/uploads/medium_7_klein_39e8828105.jpg"
          },
          "small": {
            "name": "small_7-(klein).jpg",
            "hash": "small_7_klein_39e8828105",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 39.2,
            "path": null,
            "url": "/uploads/small_7_klein_39e8828105.jpg"
          }
        },
        "hash": "7_klein_39e8828105",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 161.24,
        "url": "/uploads/7_klein_39e8828105.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.221Z",
        "updated_at": "2020-10-14T09:00:30.297Z"
      },
      {
        "id": 18,
        "name": "8-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_8-(klein).jpg",
            "hash": "thumbnail_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 8.12,
            "path": null,
            "url": "/uploads/thumbnail_8_klein_c9f10fdf0f.jpg"
          },
          "large": {
            "name": "large_8-(klein).jpg",
            "hash": "large_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 105.92,
            "path": null,
            "url": "/uploads/large_8_klein_c9f10fdf0f.jpg"
          },
          "medium": {
            "name": "medium_8-(klein).jpg",
            "hash": "medium_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 64.05,
            "path": null,
            "url": "/uploads/medium_8_klein_c9f10fdf0f.jpg"
          },
          "small": {
            "name": "small_8-(klein).jpg",
            "hash": "small_8_klein_c9f10fdf0f",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 29.64,
            "path": null,
            "url": "/uploads/small_8_klein_c9f10fdf0f.jpg"
          }
        },
        "hash": "8_klein_c9f10fdf0f",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 114.66,
        "url": "/uploads/8_klein_c9f10fdf0f.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.079Z",
        "updated_at": "2020-10-14T09:00:30.162Z"
      },
      {
        "id": 17,
        "name": "6-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_6-(klein).jpg",
            "hash": "thumbnail_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 11.88,
            "path": null,
            "url": "/uploads/thumbnail_6_klein_58e3e2670c.jpg"
          },
          "large": {
            "name": "large_6-(klein).jpg",
            "hash": "large_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 185.05,
            "path": null,
            "url": "/uploads/large_6_klein_58e3e2670c.jpg"
          },
          "medium": {
            "name": "medium_6-(klein).jpg",
            "hash": "medium_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 108.18,
            "path": null,
            "url": "/uploads/medium_6_klein_58e3e2670c.jpg"
          },
          "small": {
            "name": "small_6-(klein).jpg",
            "hash": "small_6_klein_58e3e2670c",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 47.87,
            "path": null,
            "url": "/uploads/small_6_klein_58e3e2670c.jpg"
          }
        },
        "hash": "6_klein_58e3e2670c",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 198.98,
        "url": "/uploads/6_klein_58e3e2670c.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:30.011Z",
        "updated_at": "2020-10-14T09:00:30.045Z"
      },
      {
        "id": 16,
        "name": "1-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_1-(klein).jpg",
            "hash": "thumbnail_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 7.63,
            "path": null,
            "url": "/uploads/thumbnail_1_klein_7598d5225b.jpg"
          },
          "large": {
            "name": "large_1-(klein).jpg",
            "hash": "large_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 96.9,
            "path": null,
            "url": "/uploads/large_1_klein_7598d5225b.jpg"
          },
          "medium": {
            "name": "medium_1-(klein).jpg",
            "hash": "medium_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 58.14,
            "path": null,
            "url": "/uploads/medium_1_klein_7598d5225b.jpg"
          },
          "small": {
            "name": "small_1-(klein).jpg",
            "hash": "small_1_klein_7598d5225b",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 27.5,
            "path": null,
            "url": "/uploads/small_1_klein_7598d5225b.jpg"
          }
        },
        "hash": "1_klein_7598d5225b",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 105.11,
        "url": "/uploads/1_klein_7598d5225b.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:29.886Z",
        "updated_at": "2020-10-14T09:00:29.918Z"
      },
      {
        "id": 15,
        "name": "5-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_5-(klein).jpg",
            "hash": "thumbnail_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 9.36,
            "path": null,
            "url": "/uploads/thumbnail_5_klein_544aec77de.jpg"
          },
          "large": {
            "name": "large_5-(klein).jpg",
            "hash": "large_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 130.42,
            "path": null,
            "url": "/uploads/large_5_klein_544aec77de.jpg"
          },
          "medium": {
            "name": "medium_5-(klein).jpg",
            "hash": "medium_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 77.94,
            "path": null,
            "url": "/uploads/medium_5_klein_544aec77de.jpg"
          },
          "small": {
            "name": "small_5-(klein).jpg",
            "hash": "small_5_klein_544aec77de",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 35.49,
            "path": null,
            "url": "/uploads/small_5_klein_544aec77de.jpg"
          }
        },
        "hash": "5_klein_544aec77de",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 139.03,
        "url": "/uploads/5_klein_544aec77de.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:29.708Z",
        "updated_at": "2020-10-14T09:00:29.791Z"
      },
      {
        "id": 14,
        "name": "3-(klein).jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1024,
        "height": 600,
        "formats": {
          "thumbnail": {
            "name": "thumbnail_3-(klein).jpg",
            "hash": "thumbnail_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 245,
            "height": 144,
            "size": 11.45,
            "path": null,
            "url": "/uploads/thumbnail_3_klein_d7dc4aa2fe.jpg"
          },
          "large": {
            "name": "large_3-(klein).jpg",
            "hash": "large_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 1000,
            "height": 586,
            "size": 169.78,
            "path": null,
            "url": "/uploads/large_3_klein_d7dc4aa2fe.jpg"
          },
          "medium": {
            "name": "medium_3-(klein).jpg",
            "hash": "medium_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 750,
            "height": 439,
            "size": 101.06,
            "path": null,
            "url": "/uploads/medium_3_klein_d7dc4aa2fe.jpg"
          },
          "small": {
            "name": "small_3-(klein).jpg",
            "hash": "small_3_klein_d7dc4aa2fe",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "width": 500,
            "height": 293,
            "size": 44.95,
            "path": null,
            "url": "/uploads/small_3_klein_d7dc4aa2fe.jpg"
          }
        },
        "hash": "3_klein_d7dc4aa2fe",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 181.39,
        "url": "/uploads/3_klein_d7dc4aa2fe.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2020-10-14T09:00:29.526Z",
        "updated_at": "2020-10-14T09:00:29.603Z"
      }
    ]
  },
  {
    "__component": "about-us.content-slide",
    "id": 6,
    "slideTitle": "The Official Circle of Friends of the Vienna State Opera",
    "text": "The Official Circle of Friends of the Vienna State Opera brings together like-minded opera lovers who want to actively participate in the cultural events of the house. Seven levels of membership offer attractive benefits, such as preferential ordering of purchase tickets, regular invitations to exclusive Friends' Circle events, behind-the-scenes insights, and active participation in the artistic events of the Vienna State Opera. As a member, they support the outreach and education programs aimed at young people, as well as the Young Artist Program with its excellent young singers and the Opera School. Supporting the Vienna State Opera not only means being personally associated with one of the country's most important cultural institutions, but also offers the satisfaction of becoming part of an artistic dialogue and an important social mission.\n\nGet in touch with the Official Circle of Friends at freundeskreis@wiener-staatsoper.at \n\nWe are looking forward to welcoming you!\n\n"
  },
  {
    "__component": "about-us.content-slide",
    "id": 12,
    "slideTitle": "Friends of the Vienna State Ballet ",
    "text": "The Vienna State Ballet is one of the most important dance companies in the world. The Friends of the Vienna State Ballet are helping to carry this success into the future. Become a part of it and support director and chief choreographer Martin Schläpfer in his vision for the future of the company. As a thank you, you will not only receive the offer of preferred ticket orders, we have also prepared a varied and exclusive program for you: artist talks, visits to stage rehearsals and training sessions, but above all, a space for interesting personal encounters with the Team and the dancers of the Vienna State Ballet and much more.\nBecome a friend of the Vienna State Ballet and help us to continue to do what we do best: to create moments that move in the truest sense of the word!\nIf you have any questions, please do not hesitate to contact us at freundeskreis@wiener-staatsballett.at \n"
  },
  {
    "__component": "about-us.sponsor-slide-with-navigation",
    "id": 2,
    "backLink": "Startmenu",
    "nextLink": "Production"
  }
]