export default {
  it_news: 'Aktuelles',
  it_goto_home: 'Zurück zum Startmenu',
  it_contents: 'Inhalt',
  it_about: 'Über uns',
  it_faq: 'Fragen & Antworten',
  it_feedback: 'Feedback',
  it_newsletter: 'Newsletter',
  it_row: 'Reihe',
  it_seat: 'Platz',
  it_marble_hall: 'Marmorsaal',
  it_more_info: 'Mehr Informationen',
  it_insights: 'News',
  all_german: 'Deutsch',
  all_english: 'English',
  all_more: 'Mehr',
  all_start: 'Beginn',
  all_break: 'Pause ca.',
  all_end: 'Ende',
  all_next: 'Weiter',
  all_back: 'Zurück',
  it_uhr: 'Uhr',
  it_faq_short: 'FAQ',
  production_today: 'HEUTIGE VORSTELLUNG',
  det_cast: 'BESETZUNG',
  det_leadingteam: 'LEADING TEAM',
  det_sponsor: 'Diese Produktion wird ermöglicht mit Unterstützung von',
  det_summary: 'Inhaltsangabe',
  det_more: 'Mehr im Programmheft beim Billeteur',
  det_dl: 'oder zum Herunterladen',
  det_dates: 'WEITERE TERMINE',
  available_as_stream:
    'Diese Produktion können Sie auch als Livestream auf <i>→&nbsp;staatsoperlive.com</i> erleben. Für mehr Informationen über unser Livestream-Programm tragen Sie sich bitte für unseren Newsletter ein.',
  det_email: 'Ihre E-mail Adresse',
  det_gensp: 'GENERALSPONSOREN DER WIENER STAATSOPER',
  feedback_title: 'Feedback',
  feedback_intro:
    'Helfen Sie uns, unser Angebot für Sie zu verbessern, indem Sie uns ein paar Fragen beantworten.',
  feedback_question: 'Frage',
  feedback_of: 'von',
  faq_headline: 'Fragen & Antworten',
  gastro_introtext:
    "<span class='italic'>Genießen Sie in der Pause in Ruhe<br>und ohne Anstellen Ihre Erfrischung:</span><br><br>Sie können von hier aus bequem mit wenigen Klicks<br>die beliebtesten Angebote vorbestellen.<br>Das Gerstner-Team freut sich auf Ihre Wünsche und <br>bereitet Ihre Bestellung im Marmorsaal vor. <br>Bezahlung bei Abholung!",
  gastro_price: 'Preis',
  gastro_amount: 'Anzahl',
  gastro_title: 'Gastronomie',
  gastro_widgettitle: 'Bestellung für die Pause',
  gastro_strong1: 'Auf unserer Speisekarte ',
  gastro_text1:
    'haben wir beliebte Angebote vorbereitet, die Sie bequem, mit wenigen Klicks, von Ihrem Sitzplatz aus bestellen können.',
  gastro_strong2: 'Genießen Sie in Ruhe ',
  gastro_text2:
    'Ihre Erfrischung ohne Anstellen, an einem reservierten Tisch, oder an der Bar. Das Gerstner Catering Team freut sich auf Ihre Bestellung.',
  gastro_offerbtn: 'Zum Angebot',
  gastro_loadappstrong: 'Laden Sie die „Bestell-App“ von Gerstner herunter,',
  gastro_loadappnormal: 'um schon frühzeitig Bestellungen vornehmen zu können.',
  gastro_recommandations: 'Unsere Empfehlungen',
  gastro_food: 'SPEISEN',
  gastro_beverages: 'GETRÄNKE',
  gastro_packages: 'Pakete',
  gastro_order: 'Weiter',
  gastro_yourorder: 'Ihre Bestellung',
  gastro_total: 'Total',
  gastro_back: 'Zurück',
  gastro_placeorder: 'Bestellung abschließen',
  gastro_thanks: 'Danke für Ihre Bestellung',
  gastro_ordersum: 'Summe Ihrer Bestellung ',
  gastro_confirmation:
    'Unter welchem Namen möchten Sie Ihre Bestellung beim Gerstner Pausen Service im Marmorsaal abholen?',
  gastro_delete: 'Löschen',
  gastro_confirm: 'Bestätigen',
  gastro_sendorder: 'Bestellung senden',
  gastro_cancel: 'Abbrechen',
  gastro_confirmheadline: 'Bestätigen Sie Ihre Bestellung',
  gastro_orderpickupinfo:
    'Ihre Bestellung wird für Sie in der nächsten Pause im Marmorsaal zur Abholung bereitgestellt.',
  gastro_alergen:
    'Unsere Mitarbeiter und Mitarbeiterinnen an den Pausenbuffets informieren Sie gerne über allergene Zutaten in unseren Produkten.',
  gastro_reservationname: 'Reservierungsname',
  gastro_locationplan: 'Abholpunkt',
  gastro_name_label: 'Ihr Name',
  gastro_sending: 'Ihre Bestellung wird übermittelt ...',
  news_insights: 'News',
  newsletter_header: 'Newsletter',
  newsletter_intro: "Bleiben Sie auf dem Laufenden mit unseren Newslettern zu verschiedenen Themen und erfahren Sie alle Neuigkeiten aus dem Haus am Ring.",
  newsletter_description:
    'Ja, ich möchte nähere Informationen zu folgenden Themen erhalten:',
  newsletter_news: 'NEWSLETTER ALLGEMEIN',
  newsletter_jugend: 'U27 / JUNGES PUBLIKUM',
  newsletter_familyfriends: 'family & friends',
  newsletter_ballett: 'WIENER STAATSBALLETT',
  newsletter_label: 'TIPPEN SIE HIER, UM IHRE E-MAIL ADRESSE EINZUTRAGEN',
  newsletter_placeholder: 'E-mail Adresse eintragen',
  newsletter_btn_back: 'zurück',
  newsletter_btn_subscribe: 'abonnieren',
  newsletter_wrong_email: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
  newsletter_subscriber_exists: 'Diese E-Mail Adresse ist bereits registriert.',
  newsletter_success:
    'Vielen Dank für Ihre Anmeldung! <br><br>Um Ihre Registrierung abzuschließen, erhalten Sie in Kürze eine Email mit einem Bestätigungslink.<br><br> Ihr Team der Oper',
  read_more_in_programm: 'Mehr im Programmheft',
  read_more_in_where:
    'beim Billeteur oder digital für Ihr Mobilgerät in der Oper Publikationen App.',
  visitor_greeting: 'Liebe Besucherin, lieber Besucher',
  visitor_confirm: 'Bestätigen',
  change_language: 'Sprache ändern',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  it: 'Italiano',
  fr: 'Français',
  ru: 'Pусский',
  ja: '日本語',
  zh: '中文',
  ov: 'Originalversion',
  select_language_headline: 'Bitte wählen Sie die Sprache für die Untertitel',
  warning: {
    headline: 'Keine Bild- und Tonaufnahmen!',
    salut: 'Sehr geehrte Besucherinnen und Besucher,',
    switch: 'die Untertitel erscheinen',
    turnoff: 'das Infotainment deaktiviert sich',
    desc: 'Fotos, Videos oder Tonaufnahmen sind nicht gestattet!',
    thanks: 'Bitte schalten Sie Ihr Mobiltelefon aus.<br>Wir wünschen eine anregende Vorstellung.'
  },
  x_minutes: ['in {count} Minute', 'in {count} Minuten'],
  x_seconds: ['in {count} Sekunde', 'in {count} Sekunden'],
  close: 'schließen',
  help: 'Hilfe',
  help_description: `
        <p>
          Willkommen im Untertitel-<br> und Informationssystem der Wiener
          Staatsoper.<br>
        </p>
        <p>
          Kurz vor Vorstellungsbeginn schalten wir <br />automatisch auf die
          Untertitel-Funktion um: <br />
          Sie k&#xF6;nnen dann die gew&#xFC;nschte Sprache ausw&auml;hlen oder
          das Ger&auml;t abschalten. <br />
        </p>
  `,
  done: 'Fertig',
  nav: {
    main: 'Zurück zum Hauptmenü',
    about: 'Über uns',
    production: 'Informationen zum heutigen Werk',
    next: {
      production: 'Informationen zum heutigen Werk',
      about: 'Über uns',
      faqs: 'Zu den Faqs',
      help: 'Hilfe ansehen',
    },
    back: {
      startmenu: 'Zurück zum Hauptmenü',
      production: 'Informationen zum heutigen Werk',
    }
  },
  program: {
    roundIconInfo: 'Erhältlich &nbsp; beim &nbsp; Publikumsdienst &nbsp; im &nbsp; Foyer &nbsp;&nbsp;&nbsp;&nbsp;',
    heading: 'Das Abendprogramm<br>zur heutigen Vorstellung',
    desc: 'Das Abendprogramm zur heutigen Vorstellung mit <br>vielfältigen Hintergrundinformationen, Besetzungszettel, <br>Szenenfotos, erläuternden Essays und Interviews <br>erhalten Sie bei den Billetteurinnen und Billetteuren<br> und an den Tageskassen.',
    desc2: ''
  },
  ironCurtainInfo: 'Der Eiserne Vorhang der<br>Saison 2024/25 wurde gestaltet<br>von Carrie Mae Weems.',
  supportedBy: 'Mit freundlicher Unterstützung der <br>Christian Zeller Privatstiftung',
  general_sponsors_thanks: 'Die Oper bedankt<br>sich bei ihren Generalsponsoren!',
  gastro: {
    offeredby: 'Ein Angebot von'
  },
  survey: {
    headline: 'Ihre Meinung ist uns wichtig!',
    intro: 'Bitte beantworten Sie {count} kurze Fragen.',
    cancel: 'Umfrage abbrechen',
    start: 'Starten',
    outro_headline: 'Vielen Dank für Ihre Teilnahme',
    outro_text: '',
    back: 'Zurück zum Hauptmenü',
  },
  blocknotification: {
    default: 'Nicht über die Brüstung lehnen!',
  },
  prescreen:  {
    production: 'Die Produktion',
    supported_by: 'wird unterstützt von'
  },
  newsletter_privacy: `
    Ihre Daten werden ausschließlich für den Versand der von Ihnen ausgewählten Newsletter durch die Oper (bzw. ihren Auftragsdatenverarbeiter) verwendet und nicht an Dritte weitergegeben.<br><br>
    Die Einwilligung zum Erhalt unserer Newsletter kann selbstverständlich jederzeit über den Abmelde-Link in jedem Newsletter widerrufen werden.
  `,
  intermissionFollowup: {
    text: "Florenz im Karneval, vor nicht allzu langer Zeit.<br><br>Buoso Donati beim Abendessen.<br><br>Radio Mussoloni spielt faschistische Lieder und Reden aus fünf Jahrzehnten."
  }
}
