import Vue from 'vue'
import Router from 'vue-router'
import Start from './views/Start.vue'
import ProductionDetail from './views/PrdDetail.vue'
import Home from './views/Home.vue'
import Prescreens from './views/Prescreens.vue'
import About from './views/About.vue'
import Help from './views/Help.vue'
import News from './views/News.vue'
import Newsletter from './views/Newsletter.vue'
import Faq from './views/Faq.vue'
import NewsDetail from './views/NewsDetail.vue'
import Subtitles from './views/Subtitles.vue'
import Gastro from './views/Gastro.vue'
import Intermission from './views/Intermission.vue'
import IntermissionFollowup from './views/IntermissionFollowup.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/prescreens',
      name: 'prescreens',
      component: Prescreens,
    },
    {
      path: '/intermission',
      name: 'intermission',
      component: Intermission,
    },
    {
      path: '/start',
      name: 'startmenu',
      component: Start,
    },
    {
      path: '/production-detail',
      name: 'production',
      component: ProductionDetail,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: Newsletter,
    },
    {
      path: '/faq',
      name: 'faqs',
      component: Faq,
    },
    {
      path: '/news/:id',
      name: 'newsdetail',
      component: NewsDetail,
      props: true,
    },
    {
      path: '/subs',
      name: 'subtiteling',
      component: Subtitles,
    },
    {
      path: '/gastro',
      name: 'gastro',
      component: Gastro,
    },
    {
      path: '/intermission-followup',
      name: 'intermissionfollowup',
      component: IntermissionFollowup,
    },
  ],
})
