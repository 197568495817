<template>
  <article class="content-slide sponsors">
   <div class="text-container">
      <div class="headline">
        Wir bedanken uns bei unseren Generalsponsoren!
      </div>
      <div class="sponsor">
        <img src="/img/logo-lexus-and-omv-2023.png" />
      </div>
    </div>
    <div class="bottom-nav">
      <div class="prev" @click.stop="$router.push({ name: backLink })">&larr;&nbsp;{{ `nav.back.${backLink}` | translate }}</div>
      <div class="next" @click.stop="$router.push({ name: nextLink })">{{ `nav.next.${nextLink}`  | translate }}&nbsp;&rarr;</div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    backLink() {
      return this.slide.backLink.toLowerCase()
    },
    nextLink() {
      return this.slide.nextLink.toLowerCase()
    }
  },
}
</script>

<style scoped>

.content-slide.sponsors {
  position: relative;
  height: 600px;
}

.text-container {
  text-align: center;
  padding: 20px 0;
  height: 540px;
  position: relative;
}
.headline {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 30px;
  line-height: 35px;
  text-overflow: ellipsis;
  z-index: 2;
}

.sponsor {
  position: absolute; 
  bottom: 180px;
  padding: 40px;
  font-size: 22px;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-nav {
  position: absolute;
  width: 100%;
  height: 55px;
  display: flex;
  left: 0;
  bottom: 0;
  align-items: center;
  font-family: 'GT Super Display';
  font-size: 20px;
  border-top: 1px solid #ccc;
}
.bottom-nav > div:first-child {
  border-right: 1px solid #ccc;
}
.bottom-nav > div {
  width: 50%;
  height: 100%;
  line-height: 55px;
  text-transform: uppercase;
}
</style>
