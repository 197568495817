export const getDefaultProduction = lang => {
  const title =
    lang === 'de'
      ? 'Willkommen im Musiktheater'
      : 'Welcome to the Music Theatre'

  return {
    id: 999999999,
    type: 'production',
    language: lang,
    useGroupBy: false,
    title,
    overallTitle: '',
    timeFrom: new Date(),
    timeTo: new Date(),
    teaserImage:
      'static/images/1024x600csm_fallback_jakwerth_048_c6d26fec56.jpg',
    headerImage:
      'static/images/1024x600csm_fallback_jakwerth_048_c6d26fec56.jpg',
    shortDescription: '',
    pauseInformation: null,
    numberOfBreaks: 0,
    Casts: [],
    sponsors: [],
    timeFromDisplay: '08:00',
    timeToDisplay: '22:00',
  }
}

export const de = 'de'
