<template>
  <div class="sponsors-wrapper" :style="`background-image: url(${backgroundImage})`">
    <div class="text-container">
      <div class="headline">
        Wir bedanken uns bei unseren Generalsponsoren!
      </div>
      <div class="sponsor">
        <img src="/img/logo-lexus-and-omv-2023.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('app', ['config']),
    backgroundImage() {
      return '/images/Madama_Butterfly-min.jpg'
    }
  }
}
</script>

<style scoped>
.sponsors-wrapper {
  width: 1024px;
  height: 600px;
  color: #ddd;
  position: relative;
  background-size: cover;
}
.sponsors-wrapper:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  z-index: 1;
}


.text-container {
  width: 1024px;
  padding: 40px;
  height: 600px;
  position: absolute;
  top: 0; left: 0;
  z-index: 2;
  text-align: center;
}
.headline {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 60px;
  line-height: 55px;
  text-overflow: ellipsis;
  z-index: 2;
}


.sponsor {
  position: absolute; 
  bottom: 150px;
  padding: 40px;
  font-size: 22px;
  align-items: center;
  font-family: 'GT Super Vienna Display';
  left: 50%;
  transform: translateX(-50%);
}
</style>