<template>
  <article :data-navgroup="navgroup" class="content" style="position: relative;">
    <h3 class="text-uppercase">{{ 'det_summary' | translate }}</h3>
    <div class="summary" v-html="contentSlides"></div>
  </article>
</template>

<script>
export default {
  props: {
    contentSlides: {
      type: String,
      default: () => '',
    },
    navgroup: [String, Number]
  },
}
</script>

<style scoped>
h3 {
  margin: 10px 0 60px 0;
}
.summary {
  padding: 0 20px;
}
.summary >>> p {
  margin: 20px 0;
}
</style>