<template>
  <div
    class="home container-full-height"
    v-if="production"
  >
    <video muted loop autoplay width="100%" height="100%" id="bg-video">
      <source src="/assets/robin-hood-trailer.mp4" type="video/mp4" />
    </video>
    <div class="content-wrapper" id="content">
      <div class="header">
        <dynamic-logo></dynamic-logo>
      </div>

      <div class="production-info">
        <div class="title">Willkommen</div>
        <div class="subtitle">lemon42 & DH electronics</div>
      </div>

      <div class="button-wrapper">
        <button @click.stop="next('de')">Deutsch</button>
        <button @click.stop="next('en')">English</button>
      </div>

      <div class="footer">
        Parterre Rechts: Reihe 3, Platz {{ Math.floor(Math.random() * 10) + 1}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import DynamicLogo from '@/components/DynamicLogo.vue'

export default {
  name: 'home',
  components: { DynamicLogo },
  computed: {
    ...mapState('app', ['production', 'locale', 'config']),
  },
  methods: {
    next(lang = 'de') {
      moment.locale(lang)
      this.$store.commit('app/CHANGE_LOCALE', lang)
      this.$i18n.set(lang)
      this.$router.push('prescreens')
    },
  },
  async mounted() {
    await this.$store.dispatch('app/initUnit')
  },
}
</script>

<style scoped>
.content-wrapper {
  margin: 0 auto;
  display: block;
  text-align: center;
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.header {
  text-align: left;
  height: 160px;
  width: 100%;
  padding: 16px;
}

.production-info {
  max-height: calc(600px - 160px - 63px);
  padding: 36px 0;
}

.button-wrapper {
  height: 72px;
}
.button-wrapper button {
  width: 320px;
  font-family: 'GT Super Vienna Text Book', sans-serif;
  margin: 0 10px;
  border: solid 2px;
  font-size: 18px;
  line-height: 33px;
  border-radius: 0;
  color: black;
  background-color: rgb(225, 225, 225);
  border-color: rgb(225, 225, 225);
  border-radius: 50px;
  text-transform: uppercase;
}

.subtitle {
  font-family: 'GT Super Vienna Display';
  font-size: 30px;
  line-height: 30px;
  align-self: end;
  margin: 30px 0;
}

.title {
  font-family: 'GT Super Vienna Display';
  font-size: 60px;
  line-height: 60px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  font-size: 34px;
  height: 63px;
  line-height: 63px;
  font-size: 30px;
  position: absolute;
  display: BLOCK;
  bottom: 0;
  background: black;
  font-family: 'GT Super Vienna Display';
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}
.footer i {
  font-family: 'GT Super Vienna Display Italic';
  text-transform: none;
}

#bg-video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
  width: 1024px;
  height: 600px;
}

#content {
  position: relative;
  z-index: 1;
  background-color: transparent;
}
</style>
