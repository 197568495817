export const namespaced = true

export const state = {
  libretto: [],
  selectedLang: null,
  showLangSelection: true,
  activeFrame: null,
  activeLanguages: [],
  passedFramesSinceLastLangChange: 0,
  librettoDisplayMode: 'standard',
  lastActiveCue: 0,
}

export const mutations = {
  SET_LANGUAGE(state, lang) {
    state.selectedLang = lang
    state.activeFrame = null
  },
  SHOW_LANG_SELECTION(state, active) {
    state.passedFramesSinceLastLangChange = 0
    state.showLangSelection = active
  },
  SET_CUE(state, cueId) {
    state.lastActiveCue = cueId
    state.passedFramesSinceLastLangChange += 1
    if (Array.isArray(state.libretto) && state.libretto[cueId]) {
      state.activeFrame = state.libretto[cueId][state.selectedLang] || ''
    }
  },
  SET_LIBRETTO(state, libretto) {
    state.libretto = libretto
  },
  SET_LIBRETTO_DISPLAY_MODE(state, librettoDisplayMode='standard') {
    state.librettoDisplayMode = librettoDisplayMode
  },
  SET_ACTIVE_LANGUAGES(state, languages = []) {
    state.activeLanguages = languages
  },
  INIT(state) {
    state.selectedLang = null
    state.selectedLanguageDisplayname = ''
    state.activeFrame = null
    state.passedFramesSinceLastLangChange = 0
  },
  RESET_FRAMES_PASSED(state) {
    state.passedFramesSinceLastLangChange = 0
  },
}
