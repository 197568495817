<template>
  <div id="app" :class="$route.name + (hydrating ? ' hydrating' : '')">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
    <div
      :class="{
        'inactive-overlay': true,
        visible: !activated,
      }" @click.stop="$store.commit('app/TURN_ON_OFF', true)"
    >
      <div class="power-off-icon-wrapper" v-show="showPowerButton">
        <base-icon viewBox="0 0 42 42" :width="96" :height="96">
          <power-icon stroke="#666" />
        </base-icon>
      </div>
        
    </div>

    <warning-notification></warning-notification>
    <survey-container v-if="showSurvey"></survey-container>

    <div class="disabled-overlay" :class="{ visible: disabled || hydrating }"></div>
    <screen-blocker></screen-blocker>
    <screen-blocker-custom></screen-blocker-custom>
    <ScreenBlockerPhone></ScreenBlockerPhone>
    <device-config v-if="showDeviceConfig" @click.native.stop="showDeviceConfig=false"></device-config>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WarningNotification from '@/components/WarningNotification.vue'
import ScreenBlocker from '@/components/notification/ScreenBlocker.vue'
import ScreenBlockerCustom from '@/components/notification/ScreenBlockerCustom.vue'
import ScreenBlockerPhone from '@/components/notification/ScreenBlockerPhone.vue'
import DeviceConfig from '@/components/notification/DeviceConfig.vue'
import SurveyContainer from '@/components/survey/SurveyContainer.vue'
import { getDefaultProduction } from '@/data/production'
import { getProduction } from './store/modules/static-production.js'

import PowerIcon from '@/components/icons/PowerOff.vue'

let esKeepAliveTimer = null
const RECONNECT_TIME = 5 * 60000

export default {
  components: { WarningNotification, SurveyContainer, ScreenBlocker, ScreenBlockerCustom, DeviceConfig, ScreenBlockerPhone, PowerIcon },
  data() {
    return {
      notifications: null,
      hydrating: true,
      showDeviceConfig: false,
    }
  },
  computed: {
    ...mapState('app', ['activated', 'config', 'unit', 'disabled', 'locale']),
    showSurvey() {
      if (this.config.IS_PREVIEW_BUILD) {
        return false
      }
      return false
      return this.activated && !this.disabled && this.$route.name === 'startmenu'
    },
    showITBlockMessage() {
      return this.activated && !this.disabled && this.$route.name !== 'subtiteling'
    },
    showPowerButton() {
      return this.$route.name === 'subtiteling'
    }
  },
  watch: {
    locale() {
      this.updateProductionData()
    },
  },
  methods: {
    async handleSSE(e) {

      if (this.config.IS_PREVIEW_BUILD) {
        return
      }

      // on message we reinit the reconnect timer to avoid not needed
      // reconnects
      try {
        if (esKeepAliveTimer) {
          clearTimeout(esKeepAliveTimer)
        }
        esKeepAliveTimer = setTimeout(() => {
          this.connectEventSource()
        }, RECONNECT_TIME)
      } catch (e) {
        // nothing todo here
      }

      let data = {}

      try {
        data = JSON.parse(e.data)
      } catch (e) {
        return
      }

      if (data.cue || data.cue === 0) {
        this.$store.commit('subs/SET_CUE', data.cue)
        return
      }

      if (data.action) {
        switch (data.action) {
          case 'IT_ON':
            if (this.$route.name === 'subtiteling') {
              this.$router.push({ name: 'intermission' }).catch(() => {})
            }
            this.$store.commit('app/ACTIVATE_INFOTAINMENT')
            break
          case 'IT_OFF':
            this.$store.commit('app/DEACTIVATE_INFOTAINMENT')
            break
          case 'BLOCK_NOTIFICATION_ON':
            if (Array.isArray(data.rcpts)) {
              if (data.rcpts.indexOf(this.unit.ip) === -1) {
                return
              }
            }
            this.$store.commit('app/SET_SHOW_BLOCK_NOTIFICATION', true)
            this.$store.commit('app/SET_BLOCK_NOTIFICATION_TEXT', data.text)
            this.$store.commit('app/SET_BLOCK_NOTIFICATION_ICON', data.icon)
            break
          case 'BLOCK_NOTIFICATION_OFF':
            this.$store.commit('app/SET_SHOW_BLOCK_NOTIFICATION', false)
            this.$store.commit('app/SET_BLOCK_NOTIFICATION_TEXT')
            break

          case 'DEVICE_CONFIG_ON':
            this.showDeviceConfig = true
            break
          case 'DEVICE_CONFIG_OFF':
            this.showDeviceConfig = false
            break

          case 'SWITCH_TIMER':
          case 'SHUTDOWN_TIMER':
            // eslint-disable-next-line no-case-declarations
            const seconds = parseInt(data.seconds, 10)
            // eslint-disable-next-line no-case-declarations
            const type = data.action === 'SWITCH_TIMER' ? 'switch' : 'it_off'

            this.$store.commit('app/SET_TIMER', {
              seconds,
              type,
            })

            if (!data.resent) {
              this.$store.commit('app/SET_SHOW_ALERT', true)
            }

            break
          case 'CANCEL_TIMER':
            this.$store.commit('app/SET_TIMER', {
              seconds: -1,
              type: 'it_off',
            })

            if (!data.resent) {
              this.$store.commit('app/SET_SHOW_ALERT', false)
            }

            break
          case 'HIDE_SUBS':
            this.$store.commit('app/DEACTIVATE_INFOTAINMENT')
            break
          case 'SHOW_SUBS':
            this.$store.commit('app/ACTIVATE_INFOTAINMENT')
            break
          case 'START_SUBS':
            this.$store.commit('app/START_SUBTITELING')

            if (this.$route.name !== 'subtiteling') {
              this.$store.commit('subs/INIT')
              this.$router.push({ name: 'subtiteling' }).catch(() => {})
              this.$store.commit('app/SET_SHOW_BLOCK_NOTIFICATION', false)
              this.$store.commit('app/SET_BLOCK_NOTIFICATION_TEXT')
            }
            
            break
          case 'LIBRETTO_UPDATED':
            this.$store.commit('subs/INIT')
            this.$store.commit('subs/SET_LIBRETTO', [])

            const { data: activeLanguages } = await this.$axios.get(
              `${this.config.DATA_API}/activelanguages`
            )
            this.$store.commit('subs/SET_ACTIVE_LANGUAGES', activeLanguages)

            break;
          case 'RELOAD_LIBRETTO':
            this.$store.commit('subs/RELOAD_LIBRETTO')
            break
          case 'FORCE_RELOAD':
            window.location.reload(true)
            break
          case 'FORCE_PAGE_CHANGE':
            this.$router.push({ name: data.page || 'home' }).catch(e => {})
            break
        }
      }
      return
    },
    connectEventSource() {
      if (this.config.IS_PREVIEW_BUILD) {
        return
      }
      
      if (this.notifications !== null) {
        try {
          this.notifications.close()
        } catch (e) {}
      }
      const notifications = new EventSource(this.config.STREAM_SOURCE, {})
      notifications.onmessage = this.handleSSE.bind(this)
      this.notifications = notifications
      // to make our eventsource more reliable we reconnect automatically after
      // RECONNECT_TIME, to get a connection no matter what
      if (esKeepAliveTimer !== null) {
        clearTimeout(esKeepAliveTimer)
      }
      esKeepAliveTimer = setTimeout(() => {
        this.connectEventSource()
      }, RECONNECT_TIME)
    },
    async updateProductionData() {
      const production = getProduction()
      if (
        Object.keys(production).length === 0 &&
        production.constructor === Object
      ) {
        this.$store.commit(
          'app/UPDATE_PRODUCTION',
          getDefaultProduction(this.locale)
        )
      } else {
        // production.shortDescription = 'Lorem ipsum dolor sit ahmet un set iteram'.repeat(20)
        this.$store.commit('app/UPDATE_PRODUCTION', production)
      }
    },
  },
  async created() {
    this.hydrating = true
    try {
      await this.updateProductionData()
      this.connectEventSource()

      window.addEventListener(
        'focus',
        this.connectEventSource.bind(this),
        false
      )

      let { data: mode } = await this.$axios.get(
        `${this.config.DATA_API}/status/mode`
      )

      if (this.config.IS_PREVIEW_BUILD) {
        mode = 'infotainment'
      }

      await new Promise(resolve => {
        setTimeout(resolve, 1000)
      })

      if (this.$route.name !== 'home' && this.$route.name !== 'startmenu') {
        await this.$store.dispatch('app/initUnit')
      }

      if (mode === 'subtiteling' || mode === 'subtitling') {
        this.$router.push('/subs').catch(() => {})
      } else if (this.$route.fullPath === '/subs') {
        this.$router.push('/').catch(() => {})
      }
    } catch (e) {
      //noop
    }

    this.hydrating = false
  },
}
</script>

<style>
@import './css/_variables.css';
@import './css/_general.css';

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: black;
}

* {
  box-sizing: border-box;
  font-weight: normal;
}

#app {
  display: block;
  width: 1024px;
  height: 600px;
  background: black;
  color: white;
  font-family: 'GT Super Vienna Display';
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.35s ease-in;
  border-bottom: 1px solid #333;
  border-right: 1px solid #333;
}
#app.hydrating {
  opacity: 0;
}


.layout-with-nav {
  display: block;
  position: relative;
}
.layout-with-nav .main-nav {
  position: absolute;
  right: 0;
  top: 0;
}

.container-full-height {
  height: 100%;
}

.layout-with-nav > *:first-child {
  height: 600px;
  width: calc(1024px - 70px);
  overflow-y: hidden;
}

.inactive-overlay,
.disabled-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1000;
  background: black;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.inactive-overlay.visible,
.disabled-overlay.visible {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.disabled-overlay {
  background: black;
  z-index: 10000;
}
.disabled-overlay.visible {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.subtiteling .inactive-overlay.visible {
  opacity: 1;
}

.tns-nav {
  display: none;
}

.power-off-icon-wrapper {
  position: absolute;
  width: 96px;
  height: 96px;

  top: 300px;
  left: 512px;

  transform: translate(-50%, -50%);
  
}
</style>
