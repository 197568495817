<template>
  <div class="title-slide">
    <h3 class="titleFont">{{ slide.slideTitle }}</h3>
    <div class="text-container">
      <h1 class="title">
        {{ slide.title }}
      </h1>
      <span class="subtitle">
        {{ slide.subTitle }}
      </span>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.title-slide {
  height: 600px;
  position: relative;

  text-align: center;
}
.title-slide h3.titleFont {
  padding-top: 20px;
}
  
.title-slide .text-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}

.title-slide .title {
  font-family: 'GT Super Vienna Display';
  font-size: 60px;
  line-height: 72px;
  margin: 0;
  text-transform: uppercase;
}
.title-slide .subtitle {
  display: inline-block;
  margin-top: 20px;
  font-size: 30px;
  line-height: 44px;
}
</style>