<template>
  <div class="iron-curtain-wrapper" :class="{ active: isActive }" style="background-image: url('/images/carrie-min.jpg')">
    <div class="text-container">
      <div class="headline" v-html="$t('ironCurtainInfo')"></div>
      <div class="sponsor">
        <span v-html="$t('supportedBy')"></span>
        <img src="/images/czstiftung.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.isActive = this.active
    },500)
    
  }
}
</script>

<style scoped>
.iron-curtain-wrapper {
  width: 1024px;
  height: 600px;
  color: white;
  position: relative;
}
.iron-curtain-wrapper:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: opacity 8s ease-in;
  z-index: 1;
}
.iron-curtain-wrapper.active:before {
  opacity: 1;
}

.iron-curtain-wrapper .text-container {
  transition: opacity 4s ease-in;
  opacity: 0;
}

.iron-curtain-wrapper.active .text-container {
  opacity: 1
}

.text-container {
  width: 1024px;
  padding: 40px;
  height: 600px;
  position: absolute;
  top: 0; left: 0;
  z-index: 2;
  text-align: center;
}
.headline {
  font-family: 'GT Super Vienna Display Italic';
  font-size: 60px;
  line-height: 55px;
  text-overflow: ellipsis;
  z-index: 2;
}


.sponsor {
  position: absolute; 
  bottom: 0;
  padding: 40px;
  font-size: 22px;
  display: flex;
  align-items: center;
  font-family: 'GT Super Vienna Display';
  left: 50%;
  transform: translateX(-50%);
}
.sponsor span {
  margin-right: 20px;
  display: block;
  white-space: nowrap;
}
</style>