I<template>
  <div class="video-wrapper" :class="{ active: showVideo }">
    <video muted autoplay width="1024" height="600"
      ref="video"
      class="preview-item video-js"
      preload="none"
    ></video>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      showVideo: false,
    }
  },
  computed: {
    ...mapState('app', ['config'])
  },
  methods: {
    loadVideo() {
      const video = this.$refs.video
      // video.currentTime = 0
      // eslint-disable-next-line no-undef
      if (Hls && Hls.isSupported()) {
        // eslint-disable-next-line no-undef
        var hls = new Hls()
        hls.loadSource(this.config.CMS_API + this.slide.video.url.replace(".mp4", ".m3u8"))
        hls.attachMedia(video)
        // eslint-disable-next-line no-undef
        hls.on(
          // eslint-disable-next-line no-undef
          Hls.Events.MANIFEST_PARSED,
          async function() {
            try {
              // video.currentTime = 0
              await video.play()
              setTimeout(() => {
                this.showVideo = true
              }, 2000)
              // eslint-disable-next-line no-empty
            } catch (e) {
              // noop
            }
          }.bind(this)
        )
      }
    },
  },
  mounted(){
    this.loadVideo()
  }
}
</script>

<style scoped>
.video-wrapper {
  width: 1024px;
  height: 600px;
  background: black;
  position: relative;
}
.video-wrapper:before {
  position: absolute;
  z-index: 10;
  background-color: black;
  opacity: 1;
  transition: opacity 1s ease-in;
}
.video-wrapper.active:before {
  opacity: 0;
}
</style>