export const de = [
  {
    key: 'Informationen zu Corona Schutzmaßnahmen',
    question: 'Waren die Informationen im Musiktheater zu den <br>Corona-Schutzmaßnahmen für Sie persönlich hilfreich?'
  },
  {
    key: 'Sicherheit heutiger Besuch',
    question: 'Wie sicher erscheint Ihnen der heutige Besuch<br>im Musiktheater?'
  },
  {
    key: 'Opernbesuch weiterempfehlen',
    question: 'Würden Sie Ihren Freunden den Besuch des Musiktheaters<br>unter den derzeitigen Bedingungen empfehlen?'
  }
]

export const en = [
  {
    key: 'Informationen zu Corona Schutzmaßnahmen',
    question: 'Was the information of the Vienna State Opera<br>regarding the coronavirus precautions helpful?'
  },
  {
    key: 'Sicherheit heutiger Besuch',
    question: `How safe did you feel during your visit<br>of the Vienna State Opera tonight?`
  },
  {
    key: 'Opernbesuch weiterempfehlen',
    question: 'Would you recommend a visit to the Vienna State Opera<br>to your friends under the current conditions?'
  }
]