<template>
  <div class="product">
    <div class="product-desc">
      <span class="product-title">{{ product.title }}</span>
      <p v-html="inlineDescription"></p>
    </div>
    <div class="product-ctrl">
      <div class="count">{{ count }} x {{ product.price | fmtPrice }}</div>
    </div>
    <div class="product-price">
      <span class="price-tag">{{
        (product.price * (count == 0 ? 1 : count)) | fmtPrice
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    count: Number,
  },
  computed: {
    inlineDescription() {
      return this.product.description.split('<br>').join(', ')
    }
  }
}
</script>

<style scoped>
.product {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: auto;
  border-top: 1px solid #ccc;
  font-size: 30px;
  padding: 20px 20px 20px 0;
}

.product-desc {
  flex-grow: 1;
}
.product-desc .product-title {
  text-transform: uppercase;
}
.product-desc p {
  font-family: 'GT Super Vienna Display Italic'; 
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 22px;
  line-height: 24px;
}

.product-ctrl {
  width: 200px;
  text-align: right;
}

.product-price {
  width: 200px;
  text-transform: uppercase;
  padding: 0 0 0 0;
  text-align: right;
}

.price-tag {
  width: 120px;
  display: inline-block;
  text-align: right;
}
</style>
