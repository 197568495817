<template>
  <div class="cast-detail-container">
    <div class="arrow-prev" @click.stop="$emit('close-cast-detail')">&larr;&nbsp;</div>
    
    <article>
      <div class="cast-detail" ref="scrollContainer">
        <div class="biography">
          <h3 class="text-uppercase">{{ `${cast.firstname} ${cast.lastname}` }}</h3>
          <div v-html="cast.biography"></div>
        </div>
        <div class="full-height" :style="`background: url(${cast.photo}) center center no-repeat`"></div>
      </div>
    </article>

    <base-nav>
      <div v-if="cast.photo && cast.biography" class="dots__dotwrapper">
        <div class="dot" @click.stop="scrollTo(idx)" :class="{ active : idx === selectedIdx } " v-for="(dot, idx) in 2" :key="idx"></div>
      </div>
    </base-nav>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import throttle from 'lodash/throttle'

export default {
  name: "castdetail",
  props: {
    cast: {},
  },
  data() {
    return {
      selectedIdx: 0
    }
  },
  computed: {
    ...mapState('app', ['production', 'config']),
  },
  methods: {
    _onScroll() {
      const scrollTop = this.$refs.scrollContainer.scrollTop
      let i = 0
      let selectedIdx = 0
      for (const child of this.$refs.scrollContainer.children) {
        if (child.offsetTop - 100 <= scrollTop) {
          selectedIdx = i
        }
        i ++;
      }
      this.selectedIdx = selectedIdx;
    },
    scrollTo(idx = 0) {
      const scrollToNode = this.$refs.scrollContainer.children[idx];
      this.$scrollTo(scrollToNode, 500, {
        container: this.$refs.scrollContainer,
        force: true,
        cancelable: false,
        x: false, y: true,
      })
    },
  },
  mounted() {
    this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    this.handleScroll = throttle(this._onScroll.bind(this), 100, {leading: true, trailing: true})
  },
}
</script>


<style scoped>
.cast-detail-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  
  z-index: 50;

  background: black;
}

.arrow-prev {
  padding: 8px 20px 0;
  font-size: 40px;
}

.cast-detail {
  height: 540px;
  padding-right: 70px;
  overflow-y: scroll;
  font-size: 30px;
  line-height: 40px;
}

h3 {
  margin: 0 0 60px 0;
}

.biography {
  margin-bottom: 100px;
  padding: 0 20px;
}

</style>