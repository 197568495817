<template>
  <div id="lang-selection">
    <div class="header">
      <h1>{{ 'select_language_headline' | translate }}</h1>
    </div>

    <div class="languages">
      <div class="left">
        <button
          @click="changeLanguage(l)"
          v-for="l in splitLangs.left"
          :key="l"
        >
          {{ l | translate }}
        </button>
      </div>
      <div class="right">
        <button
          @click="changeLanguage(l)"
          v-for="l in splitLangs.right"
          :key="l"
        >
          {{ l | translate }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('subs', ['activeLanguages', 'libretto']),
    ...mapState('app', ['config']),
    splitLangs() {
      const left = []
      const right = []
      this.activeLanguages.forEach((l, idx) => {
        if (idx % 2 === 0) {
          left.push(l)
        } else {
          right.push(l)
        }
      })

      return { left, right }
    },
  },
  methods: {
    async changeLanguage(lang) {
      this.$store.commit('subs/SET_LANGUAGE', lang)
      this.$store.commit('subs/SHOW_LANG_SELECTION', false)

      if (this.libretto.length === 0) {
        const { data } = await this.$axios.get(
          `${this.config.DATA_API}/libretto/withdisplaymode`
        )
        const {libretto, librettoDisplayMode} = data
        this.$store.commit('subs/SET_LIBRETTO', libretto)
        this.$store.commit('subs/SET_LIBRETTO_DISPLAY_MODE', librettoDisplayMode)
      }

      try {
        this.$axios.post(this.config.STATS_API, {
          query: `
              mutation addUserStats {
                addUserLanguageToStatistic(lang:"${lang}")
              }
              `,
        })
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
}
</script>

<style scoped>
#lang-selection {
  height: 100%;
  text-align: center;
  font-family: 'GT Super Vienna Display';
  text-transform: uppercase;
  padding: 48px;
}

.header {
  height: 100px;
  font-size: 24px;
}

h1 {
  font-size: 32px;
  padding: 0;
  margin: 0;
  line-height: 32px;
}

.languages {
  height: calc(100% - 100px);
  color: white;
  position: relative;
}

.left,
.right {
  position: absolute;
  height: 100%;
  width: 414px;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

button {
  border: 1px solid #ccc;
  font-family: 'GT Super Vienna Display';
  text-transform: uppercase;
  background: transparent;
  color: #b2b2b2;
  font-size: 28px;
  display: block;
  width: 100%;
  height: 60px;
  margin: 0 0 30px 0;
}
</style>
