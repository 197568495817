<template>
  <div class="gerstner-gastro-logo" :class="{ animate }">
    <gerstner-logo width="400" height="200"></gerstner-logo>
    <svg width="200" height=200 viewBox="0 0 500 500">
      <defs>
          <path id="textcircle" d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z" transform="rotate(12,250,250)"/>
      </defs>
      <text>
          <textPath xlink:href="#textcircle" fill="#fff" style="text-transform: none; font-size: 50px; font-weight: bold;"
            textLength="942" v-html="'Vorbestellung Gerstner Pausen-Catering&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></textPath>
      </text>
    </svg>
  </div>
</template>
<script>
import GerstnerLogo from '@/components/icons/GerstnerLogo.vue'
export default {
  components: { GerstnerLogo },
  data() {
    return {
      animate: false,
      animateInterval: null,
    }
  },
  methods: {
    toggleAnimate() {
      this.animate = !this.animate
    }
  },
  created(){
    this.animateInterval = setInterval(this.toggleAnimate.bind(this), 5000);
  },
  beforeDestroy(){
    clearInterval(this.animateInterval)
  }
}
</script>

<style>
@keyframes rotatingAnimation {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gerstner-gastro-logo {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: scale(0.8);
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.gerstner-gastro-logo svg {
  position: absolute;
  top: 0;
  left: 0;
}

.gerstner-gastro-logo svg:first-child {
  top: 70px;
  left: -100px;
}

.gerstner-gastro-logo.animate svg:last-child {
  animation: 1s rotatingAnimation linear;
}
</style>
